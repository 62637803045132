import _ from 'lodash';

const defaultState = {
    loading: false,
    loadingCases: false,
    openCases: null,
    isEmpty: true,
    totalItems: 0,
    error: null,
    activeChat: null,
    chatState: null,
    userOnlineStatus: null,
    messages: [],
    initialIndex: null,
    lastIndex: null,
    initialFetchReady: null,
    loadingMessages: true,
    pageSize: 30,
    fetchingMoreMessages: false,
    moreMessagesFlag: true,
    initRecordTime: null,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'ACTIVE_CHAT_SET':
            return Object.assign({}, state, {
                activeChat: action.payload.activeChat,
            });

        case "PROFESSIONAL_FETCH_OPEN_CASES_LOADING":
            return Object.assign({}, state, {
                loadingCases: true,
            });

        case "PROFESSIONAL_FETCH_OPEN_CASES_SUCCESS":
            return Object.assign({}, state, {
                loadingCases: false,
                openCases: action.payload.openCases,
            });

        case "PROFESSIONAL_FETCH_OPEN_CASES_FAILURE":
            return Object.assign({}, state, {
                loadingCases: false,
            });

        case 'CHAT_FETCH_CHAT_STATE_START':
            return Object.assign({}, state, {
                loading: true,
            });

        case 'CHAT_FETCH_CHAT_STATE_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                chatState: action.payload.chatState,
            });

        case 'CHAT_FETCH_CHAT_STATE_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            });


        case 'CHAT_FETCH_USER_ONLINE_STATUS_START':
            return Object.assign({}, state, {
                loading: true,
            });

        case 'CHAT_FETCH_USER_ONLINE_STATUS_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                userOnlineStatus: action.payload.userOnlineStatus,
            });

        case 'CHAT_FETCH_USER_ONLINE_STATUS_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            });

        case 'CHAT_FETCH_INITIAL_MESSAGES_START':
            return Object.assign({}, state, {
                loading: true,
            })

        case 'CHAT_FETCH_INITIAL_MESSAGES_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                messages: [].concat(action.payload.messages),
                initialIndex: action.payload.initialIndex,
                lastIndex: action.payload.lastIndex,
                initialFetchReady: true,
                loadingMessages: false,
            });

        case 'CHAT_MESSAGE_ADD':
            return Object.assign({}, state, {
                messages: [action.payload.message].concat(state.messages),
            });

        case 'CHAT_MESSAGE_CHANGE':
            const i = _.findIndex(state.messages, { key: action.payload.message.key });

            const m = state.messages;

            m[i] = action.payload.message;

            return Object.assign({}, state, {
                messages: m,
            });

        case 'CHAT_FETCH_MORE_MESSAGES_START':
            return Object.assign({}, state, {
                fetchingMoreMessages: true,
            });

        case 'CHAT_FETCH_MORE_MESSAGES_SUCCESS':
            return Object.assign({}, state, {
                messages: state.messages.concat(action.payload.messages),
                lastIndex: action.payload.lastIndex || state.lastIndex,
                moreMessagesFlag: action.payload.messages.length > 0,
                fetchingMoreMessages: false,
            });

        case 'CHAT_FETCH_MORE_MESSAGES_FAILURE':
            return Object.assign({}, state, {
                fetchingMoreMessages: false,
            });

        case 'CHAT_INIT_RECORD_TIME':
            return Object.assign({}, state, {
                initRecordTime: action.payload.initRecordTime,
            });

        case 'CHAT_INIT_RECORD_TIME_RESET':
            return Object.assign({}, state, {
                initRecordTime: null
            })

        case 'CLEAR_ACTIVE_CHAT':
            return Object.assign({}, state, {
                activeChat: null
            })

        case 'CHAT_RESET':
            return Object.assign({}, state, {
                loading: false,
                error: null,
                chatState: null,
                userOnlineStatus: null,
                messages: [],
                initialIndex: null,
                lastIndex: null,
                initialFetchReady: null,
                loadingMessages: true,
                pageSize: 30,
                fetchingMoreMessages: false,
                moreMessagesFlag: true,
                initRecordTime: null,
            });

        default:
            return state;
    }
}
