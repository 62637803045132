import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ChatActions } from "redux/actions";
import {
    Box,
    Typography,
    CircularProgress
} from '@material-ui/core'
import CaseItem from 'components/LeftSide/CaseItem';
import Scrollbar from "react-scrollbars-custom";

class OpenCases extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            chatLastMessageTime: {},
        };
    }

    componentDidMount() {
        this.props.fetchOpenCases(
            this.props.user.doqid
        );
    };

    onUpdateLastMessageTime = (lastMessageTime, key) => {
        this.setState(state => ({
            chatLastMessageTime: {
                ...state.chatLastMessageTime,
                [key]: lastMessageTime,
            },
        }));
    }


    onCaseItemClick = (chatId) => {
        // Set this chat as active
        this.props.setActiveChat(chatId);
    }

    render() {
        const { classes, loadingCases, openCases } = this.props;
        if (loadingCases) {
            return (
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        alignContent: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        alignItems: 'center'
                    }}>
                    <CircularProgress />
                </div>
            )
        }
        return (

            <Scrollbar
                ref={(ref) => {
                    this.scrollbar = ref;
                }}
                style={{
                    height: '100%',
                    position: 'relative',
                    width: '100%',
                }}
                contentProps={{
                    renderer: props => {
                        const { elementRef, ...restProps } = props;
                        return <div
                            {...restProps}
                            ref={elementRef}
                            className="Content"
                            style={{
                                display: 'flex',
                            }}
                        />;
                    }
                }}>

                {openCases == null ?
                    (<Box
                        className={classes.warningInfo}>

                        <img
                            src='/static/images/chat.png'
                            alt=''
                            style={{
                                opacity: 0.1
                            }} />

                        <Typography>No tienes consultas abiertas</Typography>
                    </Box>)
                    :
                    (<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}>

                        {Object.keys(openCases || {})
                            .sort((a, b) => {
                                if (!this.state.chatLastMessageTime[a] && !this.state.chatLastMessageTime[b]) {
                                    return 0;
                                } else if (this.state.chatLastMessageTime[a] && !this.state.chatLastMessageTime[b]) {
                                    return 1;
                                } else if (!this.state.chatLastMessageTime[a] && this.state.chatLastMessageTime[b]) {
                                    return -1;
                                }

                                return this.state.chatLastMessageTime[b] - this.state.chatLastMessageTime[a];
                            })
                            .map((keyName, i) => {
                                return (
                                    <CaseItem
                                        key={i}
                                        chatId={keyName}
                                        data={openCases[keyName]}
                                        chat={this.props.openCases[keyName]}
                                        onUpdateLastMessageTime={this.onUpdateLastMessageTime}
                                        onCaseItemClick={this.onCaseItemClick}
                                        openCase />)
                            })}
                    </div>)}
            </Scrollbar>
        );
    }
}

const mapStateToProps = (state) => ({
    openCases: state.chat.openCases,
    loadingCases: state.chat.loadingCases,
    user: state.context.user,
});

const mapDispatchToProps = (dispatch) => ({
    fetchOpenCases: (doqid) => dispatch(ChatActions.fetchOpenCases(doqid)),
    setActiveChat: (chatId) => dispatch(ChatActions.setActiveChat(chatId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenCases);
