import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import UserRestClient from 'http/resources/UserRestClient';
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';
const { detect } = require('detect-browser');

function getQRWebLogin() {
    return async (dispatch) => {
        dispatch({
            type: 'LOGIN_LOADING',
        })

        try {

            //obtain initial data for qr login from api
            let req = await new UserRestClient().getQRWebLogin()

            if (req.data.isFaulted) {
                return Promise.reject(
                    new ApiNonFatalError(req)
                );
            }

            const data = req.data.value

            dispatch({
                type: 'LOGIN_STATE_FIRST_STEP_UPDATE'
            })

            dispatch({
                type: 'GET_QR_WEB_LOGIN_SUCCESS',
                payload: {
                    customToken: data.customToken,
                    qrCode: data.qrCode,
                    expiredDate: data.vencimiento,
                    path: data.path,
                    pathId: data.pathId,
                },
            })

            //login 'user permissions' with custom token

            const user = await firebase.auth().signInWithCustomToken(data.customToken)

            //open request to firebase and listen to any change 'codigo' value
            const browserInfo = detect()
            const browserName = browserInfo.name
            const operatingSystem = browserInfo.os
            await firebase
                .database()
                .ref(`/${data.path}/${data.pathId}`)
                .on('value', snapshot => {
                    const data = snapshot.val()
                    if (data !== null && !!data.codigo) {
                        dispatch({
                            type: 'GET_USER_DATA_LOADING',
                        })
                        // data.codigo changes when user read QR code
                        new UserRestClient()
                            .loginWebWithQRStepTwo({
                                tokenFCM: user.user.getIdToken(),
                                code: data.codigo,
                                browserName,
                                operatingSystem
                            })
                            .then(res => {
                                if (res.data.isFaulted) {
                                    return Promise.reject(
                                        new ApiNonFatalError(res)
                                    );
                                }

                                //logout of 'user permissions' and login user
                                firebase
                                    .auth()
                                    .signOut()
                                    .then(() => {
                                        firebase.auth().signInWithCustomToken(res.data.value.customToken)
                                        dispatch({
                                            type: 'LOGIN_STATE_THIRD_STEP_UPDATE'
                                        })
                                    })
                                    .catch(error => {
                                        dispatch({
                                            type: 'UI_ALERT_SET_ERROR',
                                            payload: {
                                                error: 'Ocurrió un problema. Intente nuevamente refrescando la página',
                                            }
                                        });
                                    })
                                dispatch({
                                    type: 'GET_USER_DATA_SUCCESS'
                                })
                            })
                            .catch(error => {
                                dispatch({
                                    type: 'UI_ALERT_SET_ERROR',
                                    payload: {
                                        error: ErrorManager.toMessage(error),
                                    }
                                });
                            })
                    }
                })
        } catch (errors) {
            dispatch({
                type: 'LOGIN_RESET_STATE',
            });
            dispatch({
                type: 'UI_ALERT_SET_ERROR',
                payload: {
                    error: ErrorManager.toMessage(errors),
                }
            });
        }
    }
}
function logoutWebSession() {
    return dispatch => {
        new UserRestClient(true).logoutWebSession()
            .then(res => {
                if (res.data.isFaulted) {
                    return Promise.reject(
                        new ApiNonFatalError(res)
                    );
                }
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Usuario deslogueado',
                    }
                })
                firebase.auth().signOut()
            })
            .catch(error => {
                dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    }
                });
            })
    }

}

function sessionListener() {
    return (dispatch, getState) => {
        const { doqid } = getState().context.user
        const ref = firebase.database().ref(`ViewModels/Data/${doqid}/Configuracion/Datos/SessionWeb`)

        ref.on('value', snapshot => {
            if (snapshot.val() === null) {
                dispatch({
                    type: 'UI_ALERT_SET_SUCCESS',
                    payload: {
                        message: 'Cerrando sesión..',
                    }
                })
                firebase.auth().signOut()
            }
        })
    }
}
function reset() {
    return {
        type: 'LOGIN_RESET_STATE'
    }
}
export const LoginActions = {
    logoutWebSession,
    getQRWebLogin,
    sessionListener,
    reset
}