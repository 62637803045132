import React, { PureComponent } from 'react'
import { CaseTypes } from 'constants/index';
import moment from 'moment';
import { ChatMessageHOC } from 'components/Chat/components/messages-types/_/index';
import { Avatar, Typography } from "@material-ui/core";

class ChatCaseIntroMessageItem extends PureComponent {

	render() {
		if (!this.props.message.Data.initcarddata) return null;

		return (
			<ChatMessageHOC {...this.props} type="info">
				<div
					style={{
						borderRadius: 5,
						width: '100%',
						backgroundColor: '#fff',
						display: 'flex',
						flexDirection: 'column',
						padding: 10,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							marginBottom: 10,
							width: '100%'
						}}>


						<div>
							<Avatar
								style={{
									marginRight: 10,
								}}
								src={this.props.message.Data.initcarddata.ThumbnailId ?
									this.props.message.Data.initcarddata.ThumbnailId
									:
									''}
							/>
						</div>
						<div>
							<Typography variant='h6'>
								{`${this.props.message.Data.initcarddata.Nombre} ${this.props.message.Data.initcarddata.Apellido}`}
							</Typography>
							<Typography variant='subtitle2' >
								{(this.props.message.Data.initcarddata.TipoConsulta === CaseTypes.CONSULTATION) ? this.props.message.Data.initcarddata.TipoPrioridad === 1 ? 'Consulta Urgente' : 'Consulta' : ''}
								{(this.props.message.Data.initcarddata.TipoConsulta === CaseTypes.MESSAGE) ? 'Mensaje' : ''}
								{(this.props.message.Data.initcarddata.TipoConsulta === CaseTypes.RECIPE) ? 'Receta' : ''}
							</Typography>
						</div>

					</div>
					<div>
						<Typography variant='h6'>
							{this.props.message.Data.initcarddata.Titulo}
						</Typography>
						<Typography variant='subtitle2'>
							{this.props.message.Data.initcarddata.Descripcion}
						</Typography>
					</div>
					<div
						style={{
							marginTop: 10,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>

						<Typography
							variant='caption'>

							{moment(this.props.message.Data.datetime).format("DD/MM/YYYY, hh:mm A")}

						</Typography>
					</div>
					{
						(this.props.message.Data.initcarddata.Declaracion && (
							<div
								style={{
									marginTop: 8,
									display: 'flex',
									paddingLeft: 10,
									paddingRight: 10,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Typography
									color='secondary'
									variant='caption'
									align='center'>

									{this.props.message.Data.initcarddata.Declaracion}

								</Typography>
							</div>
						))
					}
				</div>
			</ChatMessageHOC >

		)
	}
}

export default ChatCaseIntroMessageItem;