import firebase from 'firebase/app';
import 'firebase/auth';
import ErrorManager from 'services/error-manager';
import _ from 'lodash';

/**
 *  Get results ordered.
 *
 *  @param {Object} results - The resultset.
 */
function orderBy(results) {
	const arr = Object.entries(results)
		.map(item => ({ ...item[1], key: item[0] }));

	const sorted = _.orderBy(arr.filter((item) => item.Orden),
		[item => item.Orden], ['asc'],
	)

	return {
		results: sorted,
		last: (sorted.length > 0) ? sorted[sorted.length - 1].Orden : 0,
	};
}


function getFirstPage(dispatch, doqid, pageSize) {
	const ref = firebase.database().ref(`ViewModels/Data/${doqid}/ConsultasCerradas/ListaConsultasCerradas`)

	ref.orderByChild('Orden').limitToFirst(pageSize)
		.once('value', (snapshot) => {
			if (snapshot && snapshot.val()) {
				const { results, last } = orderBy(snapshot.val());
				dispatch({
					type: 'PROFESSIONAL_FETCH_CLOSED_CASES_SUCCESS',
					payload: {
						closedCases: results,
						lastIndex: last,
					},
				});
			} else {
				dispatch({
					type: 'PROFESSIONAL_FETCH_CLOSED_CASES_SUCCESS',
					payload: {
						closedCases: [],
						lastIndex: 0,
					},
				});
			}
		}, (error) => {
			dispatch({
				type: 'PROFESSIONAL_FETCH_CLOSED_CASES_FAILURE',
			});
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: ErrorManager.toMessage(error),
				}
			});
		});
}

/**
 * Fetch closed cases.
 * @param {string} doqid The doqid.
 */

function fetchClosedCases(doqid) {
	return async (dispatch, getState) => {
		dispatch({
			type: 'PROFESSIONAL_FETCH_CLOSED_CASES_LOADING'
		})

		const ref = firebase.database().ref(`ViewModels/Data/${doqid}/ConsultasCerradas/ListaConsultasCerradas`)

		ref
			.on('value', (snapshot) => {
				const { closedCases } = getState();
				if (snapshot) {
					// On first load we get the first page
					// Also we get the first page when one patient
					// is added or removed
					if (closedCases.totals === null || closedCases.totals !== snapshot.numChildren()) {
						getFirstPage(dispatch, doqid, closedCases.pageSize)

						return dispatch({
							type: 'PROFESSIONAL_CLOSED_CASES_TOTALS',
							payload: {
								totals: snapshot.numChildren(),
							}
						});
					}
				}
			}, (error) => {
				dispatch({
					type: 'PROFESSIONAL_FETCH_CLOSED_CASES_FAILURE'
				})
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error),
					}
				});
			});
	}
}


function fetchMoreClosedCases(doqid) {
	return async (dispatch, getState) => {
		const {
			closedCases
		} = getState();
		dispatch({
			type: 'PROFESSIONAL_FETCH_MORE_CLOSED_CASES_START',
		});

        /**
         *  Get all user professionals.
         */
		firebase.database().ref(`ViewModels/Data/${doqid}/ConsultasCerradas/ListaConsultasCerradas`)
			.orderByChild('Orden')
			.startAt(closedCases.lastIndex + 1)
			.limitToFirst(closedCases.pageSize)
			.once('value', (snapshot) => {
				if (snapshot.exists()) {
					const {
						results,
						last
					} = orderBy(snapshot.val());
					setTimeout(() => {
						dispatch({
							type: 'PROFESSIONAL_FETCH_MORE_CLOSED_CASES_SUCCESS',
							payload: {
								closedCases: results,
								lastIndex: last,
							},
						});
					}, 1500)
				}
			}, (error) => {
				dispatch({
					type: 'PROFESSIONAL_FETCH_MORE_CLOSED_CASES_FAILURE',
				});
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error),
					}
				});
			});
	}
}


/**
 * Fetch open cases.
 * @param {string} doqid The doqid.
 */
function startClosedCasesListener(doqid) {
	return async (dispatch) => {
		dispatch({
			type: 'PROFESSIONAL_CLOSED_CASES_LISTENER_START',
		});

		const ref = firebase.database().ref(`ViewModels/Data/${doqid}/ConsultasCerradas/ListaConsultasCerradas`)

		ref.on('value', (snapshot) => {
			dispatch({
				type: 'PROFESSIONAL_CLOSED_CASES_LISTENER_SUCCESS',
				payload: {
					closedCases: snapshot.val(),
				},
			});
		}, (error) => {
			dispatch({
				type: 'UI_ALERT_SET_ERROR',
				payload: {
					error: ErrorManager.toMessage(error),
				}
			});
		});
	};
}

export const ClosedCasesActions = {
	fetchClosedCases,
	fetchMoreClosedCases,
	startClosedCasesListener
};
