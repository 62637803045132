import React from "react";
import {
    IconButton,
    TextField,
    Typography,
    Tooltip
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MicIcon from '@material-ui/icons/Mic';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SendIcon from '@material-ui/icons/Send';
import FileCacheDB from 'services/dexiedb';
import moment from 'moment';
import Store from "redux/index";
import { v1 as uuid } from 'uuid'

const mime = require('mime-types')

export default class ChatMessageInput extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            input: '',
            isRecording: false,
            blob: null,
            recording: false,
            showAddFiles: false,
            recordingTime: 0,
            cancel: false,
            writing: false,
            enviado: false,
            sending: false
        }

        this.recorder = null
    }

    handleShowAddFiles = () => {
        this.setState({
            showAddFiles: !this.state.showAddFiles
        })
    }

    handleInputValidation = (e) => {
        //if user press only 'Enter' send message
        if (!e.shiftKey && e.key === 'Enter') {
            this.sendTextMessage(e.target.value)
        }
        //..if user press Shift+Enter create new line
    }

    handleInputChange = (e) => {
        this.setState({
            input: !this.state.sending ? e.target.value : '',
            writing: !this.state.sending ?  e.target.value.length > 0 : false,
            sending: false
        })
    }

    sendTextMessage = (text) => {
        this.setState({
            input: '',
            sending: true,
            writing: false,
        })
        if (text.length > 0) {
            this.props.onSubmitEditing(text)
        }
    }

    onRecordAudio = () => {
        this.setState({
            recording: true
        })

        const clearAudioState = () => {
            this.setState({
                recording: false,
                recordingTime: 0,
                cancel: false
            })
        }

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                let audioChunks = []

                const options = {
                    mimeType: 'audio/mpeg4'
                }
                this.recorder = new MediaRecorder(stream, options)
                console.log(this.recorder.mimeType)
                this.recorder.start();
                this.setState({
                    recording: true
                })
                let interval
                this.recorder.onstart = (e) => {
                    if (this.state.recording) {
                        interval = setInterval(() => {
                            this.setState({
                                recordingTime: this.state.recordingTime + 1
                            });
                        }, 1000);
                    }
                }
                this.recorder.ondataavailable = (e) => {
                    audioChunks.push(e.data);
                }

                this.recorder.onstop = (e) => {
                    if (this.state.cancel) {
                        //Delete record
                        audioChunks = [];
                        clearInterval(interval);
                        clearAudioState()
                        //stop stream
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                    } else {
                        //create random id to IndexedDB
                        let id = uuid()
                        console.log(audioChunks)
                        let blob = new Blob(audioChunks, { "type": "audio/mp4" })
                        console.log(blob)
                        new FileCacheDB().add({
                            id,
                            value: blob
                        })
                            .then(res => this.props.onAudioRecorded(id));

                        audioChunks = [];
                        clearInterval(interval);
                        clearAudioState()
                        //stop stream
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                clearAudioState()
                Store.dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: 'Ocurrió un problema al grabar audios, intentelo nuevamente mas tarde o con otro navegador',
                    }
                })
            })
    }

    onSelectImageOrVideo = () => {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = "image/x-png,image/gif,image/jpeg,video/*"
        input.click()
        input.onchange = (e) => {
            let response = e.target.files[0]
            let id = uuid()
            new FileCacheDB().add({
                id,
                value: response
            })
                .then(fileId => {
                    if (mime.lookup(response.name).includes('video')) {
                        this.props.submitVideoMessage(this.props.context.user.doqid, {
                            path: fileId,
                            mimeType: mime.lookup(response.name),
                            extension: mime.extension(mime.lookup(response.name)),
                            name: response.name,
                        })
                    } else {
                        this.props.submitImageMessage(this.props.context.user.doqid, {
                            path: fileId,
                            mimeType: mime.lookup(response.name),
                            extension: mime.extension(mime.lookup(response.name)),
                            name: response.name,
                        })
                    }
                })
                .catch(() => {
                    Store.dispatch({
                        type: 'UI_ALERT_SET_ERROR',
                        payload: {
                            error: 'Ocurrió un problema al subir el archivo, intentelo nuevamente mas tarde o con otro navegador',
                        }
                    })
                })

        }
    }

    onRequestSelectFile = () => {
        const supportedMimeTypes = [
            'text/plain',
            'text/html',
            'text/richtext',
            'application/rtf',
            'application/pdf',
            'application/vnd.ms-excel',
            'application/vnd.ms-excel.addin.macroenabled.12',
            'application/vnd.ms-excel.sheet.binary.macroenabled.12',
            'application/vnd.ms-excel.template.macroenabled.12',
            'application/vnd.ms-excel.sheet.macroenabled.12',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.presentationml.slide',
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'application/vnd.openxmlformats-officedocument.presentationml.template',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
            'application/vnd.ms-powerpoint',
            'application/vnd.ms-powerpoint.addin.macroenabled.12',
            'application/vnd.ms-powerpoint.slide.macroenabled.12',
            'application/vnd.ms-powerpoint.presentation.macroenabled.12',
            'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
            'application/vnd.ms-project',
            'application/msword',
        ]
        let input = document.createElement("input");
        input.type = "file";
        input.click()
        input.onchange = (e) => {
            let response = e.target.files[0]
            let id = uuid()
            if (supportedMimeTypes.indexOf(mime.lookup(response.name)) !== -1) {
                new FileCacheDB().add({
                    id,
                    value: response
                })
                    .then(fileId => {
                        const obj = {
                            path: fileId,
                            mimeType: mime.lookup(response.name),
                            extension: mime.extension(mime.lookup(response.name)),
                            name: response.name,
                        }
                        this.props.submitFileMessage(this.props.context.user.doqid, obj)
                    })
                    .catch(() => {
                        Store.dispatch({
                            type: 'UI_ALERT_SET_ERROR',
                            payload: {
                                error: 'Ocurrió un problema al subir el archivo, intentelo nuevamente mas tarde o con otro navegador',
                            }
                        })
                    })



            } else {
                Store.dispatch({
                    type: 'UI_ALERT_SET_ERROR',
                    payload: {
                        error: `El formato ${mime.lookup(response.name)} no se encuentra soportado. Tenga en cuenta que para en enviar fotos o videos debe seleccionar la opción correspondiente en el menú`
                    }
                })
            }
        }

    }

    render() {
        return (
            <div
                style={{
                    position: "relative",
                    display: "flex",
                    bottom: 0,
                    backgroundColor: '#f0f0f0',
                    padding: 5,
                    borderBottomRightRadius: 4,
                }}>
                <div
                    style={{
                        width: "10%",
                        display: ' flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <IconButton
                        onClick={this.handleShowAddFiles}>
                        <AttachFileIcon
                            style={{
                                fontSize: 30
                            }} />
                    </IconButton>

                </div>


                <form
                    style={{
                        width: "80%",
                        position: "relative",
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        disabled={this.state.recording}
                        multiline
                        rowsMax={4}
                        value={this.state.input}
                        onKeyDown={this.handleInputValidation}
                        placeholder='Escribir un mensaje aquí'
                        onChange={this.handleInputChange}
                        fullWidth
                        variant="outlined"
                    />
                </form>
                <div
                    style={{
                        width: "10%",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <IconButton
                        disabled={!this.state.writing}
                        onClick={() => this.sendTextMessage(this.state.input)} >
                        <SendIcon
                            style={{
                                fontSize: 30
                            }} />
                    </IconButton>
                </div>
                {this.state.showAddFiles &&
                    (<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            position: 'absolute',
                            bottom: '120%',
                            left: 17,
                            zIndex: 1000,
                        }}>

                        <Tooltip
                            placement="right"
                            title="Documentos">
                            <IconButton
                                onClick={this.onRequestSelectFile}
                                style={{
                                    backgroundColor: '#365D94',
                                    margin: 5,
                                    color: '#fff'
                                }}
                            >
                                <InsertDriveFileIcon
                                    style={{
                                        fontSize: 25,
                                    }} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            placement="right"
                            title="Fotos y videos">

                            <IconButton
                                onClick={this.onSelectImageOrVideo}
                                style={{
                                    backgroundColor: '#365D94',
                                    margin: 5,
                                    color: '#fff'
                                }}
                            >
                                <PhotoLibraryIcon
                                    style={{
                                        fontSize: 25,
                                    }} />

                            </IconButton>

                        </Tooltip>

                    </div>)
                }
            </div >
        );
    }
}