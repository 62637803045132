import React from "react";
import OpenCases from "components/LeftSide/OpenCases";
import ClosedCases from "components/LeftSide/ClosedCases";
import {
    Tabs,
    Tab,
    AppBar
} from "@material-ui/core";
import LeftSideHeader from "components/LeftSide/LeftSideHeader";
import { connect } from 'react-redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div
                    style={{
                        height: '100%',
                        position: 'absolute',
                        width: '100%',
                    }}>
                    {children}
                </div>
            )}
        </div>
    );
}

function LeftSide(props) {
    const { openCases, context } = props
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr 0.5fr 6fr',
                gridColumnGap: '0px',
                gridRowGap: '0px',
                height: '85vh',
                maxHeight: '85vh',
                position: 'relative'
            }}
        >
            <div
                style={{
                    borderTopLeftRadius: 4,
                    gridArea: '1 / 1 / 2 / 2',
                    backgroundColor: '#365D94'
                }}>
                <LeftSideHeader
                    context={context} />
            </div>
            <div
                style={{
                    gridArea: '2 / 1 / 3 / 2'

                }}
            >
                <AppBar
                    position="static"
                    color="default"
                    style={{
                        boxShadow: "none",
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="tabs-consultas"
                    >
                        <Tab label={(openCases)
                            ? `Abiertas (${Object.keys(openCases).length})`
                            : 'Abiertas'} />
                        <Tab label="Cerradas" />
                    </Tabs>
                </AppBar>
            </div>
            <div
                style={{
                    gridArea: '3 / 1 / 4 / 2',
                    position: 'relative'
                }}>
                <TabPanel value={value} index={0}>
                    <OpenCases />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ClosedCases />
                </TabPanel>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    context: state.context,
    openCases: state.chat.openCases,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSide);