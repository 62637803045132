import MuiPaper from './MuiPaper';
import MuiCardHeader from './MuiCardHeader';
import MuiAppBar from './MuiAppBar';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiTextField from './MuiTextField';

export default {
    MuiPaper,
    MuiCardHeader,
    MuiAppBar,
    MuiTab,
    MuiTabs,
    MuiTextField
};
