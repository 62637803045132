import React, { PureComponent } from 'react';
import { ChatMessageHOC } from 'components/Chat/components/messages-types/_/index';
import InfoIcon from '@material-ui/icons/Info';
import { Typography } from '@material-ui/core';

class ChatDefaultMessageItem extends PureComponent {
	render() {
		return (
			<ChatMessageHOC {...this.props} type="info">
				<div
					style={{
						display: 'flex',
						borderRadius: 5,
						width: '100%',
						backgroundColor: '#eca79d',
						paddingTop: 7,
						paddingBottom: 7,
						paddingRight: 8,
						paddingLeft: 8,
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<InfoIcon
							style={{
								fontSize: 20,
								color: '#000',
								marginRight: 10,
							}} />

						<div
							style={{
								borderLeft: '1px solid #ccc',
							}}
						>
							<Typography
								style={{
									paddingLeft: 10,
								}}>
								{this.props.message.Data.mensaje}
							</Typography>
						</div>
					</div>
				</div>
			</ChatMessageHOC>
		);
	}
}

export default ChatDefaultMessageItem;
