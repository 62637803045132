import React, { PureComponent } from 'react';
import { ChatMessageHOC } from 'components/Chat/components/messages-types/_/index';
import {
	Typography,
	CircularProgress
} from '@material-ui/core';

class ChatCallMessageItem extends PureComponent {
	render() {
		return (
			<ChatMessageHOC {...this.props} type="info">
				<div
					style={{
						borderRadius: 5,
						width: '100%',
						backgroundColor: '#fff5c4a2',
						padding: 10,
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							style={{
								paddingRight: 11,
							}}
						>
							<CircularProgress
								color='secondary'
								size={20}
							/>
						</div>

						<div
							style={{
								borderLeft: '1px solid #ccc',
							}}
						>
							<Typography
								style={{
									paddingLeft: 10,
								}}>
								Aguarde llamado del doctor
							</Typography>
						</div>
					</div>
				</div>
			</ChatMessageHOC>
		);
	}
}

export default ChatCallMessageItem;
