const styles = theme => ({
	chatContainer: {
		display: 'grid',
		gridTemplateColumns: ' 1fr',
		gridTemplateRows: '1fr 9fr',
		gridColumnGap: 0,
		gridRowGap: 0,
		height: '100%',
		backgroundColor: '#E7E0D8',
		backgroundImage: `url(/static/images/bg.png)`,
		backgroundRepeat: 'repeat',
		borderBottomRightRadius: 4,
		borderTopRightRadius: 4,
		maxHeight: '85vh',
		borderLeft: '1px solid #dedede',
	},
	landingContainer: {
		position: 'relative',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderLeft: '1px solid #dedede',
	},
	topAlert: {
		backgroundColor: theme.palette.secondary.main,
		width: '100%',
		position: 'absolute',
		top: 0,
		borderTopRightRadius: 4,
		'& h4': {
			padding: 25,
			fontSize: 13,
			color: '#fff'
		}
	},
	appTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'center',
		padding: 25,
		paddingBottom: 5,
		'& img': {
			width: 50,
			margin: 5
		},
		'& h3': {
			fontSize: 15,
			fontWeight: '600',
			color: '#5e5e5e'
		}
	},
	modalPaperContainer: {
		maxHeight: 300,
		padding: 20,
		maxWidth: 500,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		'&:focus': {
			outline: 0
		}
	}
});

export default styles