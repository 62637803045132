import React, { PureComponent } from 'react';
import { ChatMessageStatus, ChatMessageTime } from 'components/Chat/components/metadata';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import { ChatMessageHOC } from 'components/Chat/components/messages-types/_/index';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
	CircularProgress,
	IconButton
} from '@material-ui/core';
import { v1 as uuid } from 'uuid'
import { connect } from "react-redux";
import { FileViewerActions } from 'redux/actions';
import FileCacheDB from 'services/dexiedb';
import Store from 'redux/index';

class ChatImageMessageItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			uri: null,
		};
	}

	async componentDidMount() {
		if (this.props.message.Data.sender === this.props.user.doqid && !this.props.message.Estado.uploaded) {
			this.uploadImage();
		}

		if (this.props.message.Data.sender !== this.props.user.doqid) {
			if (!this.props.message.Estado.leido) {
				try {
					const updates = {};

					updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/Estado/leido`] = true;

					// Set message as received and readed.
					await firebase.database().ref().update(updates);
				} catch (e) {
					// Nothing to do ...
				}
			}
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.message.MetaData) {
			if (nextProps.message.MetaData.url &&
				nextProps.message.MetaData.url !== prevState.uri) {
				return { uri: nextProps.message.MetaData.url };
			}
			else return null;
		}
		else return null;
	}

	uploadImage = () => {
		this.setState({
			loading: true,
		});
		new FileCacheDB().get({
			id: this.props.message.Data.mensaje
		})
			.then(res => {
				if (!!res) {
					const ref = firebase.storage().ref(`/consultas/${this.props.chatId}/${this.props.message.Data.sender}/images/${uuid()}.${this.props.message.Data.extension}`)
					ref
						.put(res.value, {
							cacheControl: 'max-age=31536000',
							contentType: this.props.message.Data.mimeType,
						})
						.then(() => ref.getDownloadURL())
						.then((url) => {
							const updates = {};

							updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/MetaData/url`] = url;
							updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/Estado/uploaded`] = true;

							firebase.database().ref().update(updates)
								.then(() => {
									this.timeout = setTimeout(() => {
										this.setState({
											loading: false,
										});
									}, 1000);
								});
						})

						.catch((err) => {
							this.setState({
								loading: false,
							});
						});
				}
			})
			.catch(() => {
				this.setState({
					loading: false,
				});
				Store.dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: 'Ocurrió un problema al subir el archivo, intentelo nuevamente mas tarde o con otro navegador',
					}
				})
			})
	}

	openFileViewer = () => {
		this.props.openFileViewer({
			type: 'image',
			message: this.props.message,
			me: this.props.me,
			thumbnailId: this.props.thumbnailId
		})
	}

	render() {
		if (!this.props.message) return null;
		if (!this.props.message.Data) return null;

		if (this.props.message.Data.sender !== this.props.user.doqid
			&& !this.props.message.Estado.uploaded) {
			return null;
		}

		return (
			<ChatMessageHOC {...this.props} type="image">
				<div
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'center',
						justifyContent: 'flex-end',
						position: 'absolute',
						bottom: 2,
						right: 10,
						zIndex: 100,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							height: 18,
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<ChatMessageStatus
							me={this.props.me}
							status={this.props.message.Estado}
							uploaded={this.props.message.Estado.uploaded}
							hideMessageReadedStatus={this.props.hideReadedStatus}
						/>

						<ChatMessageTime
							time={this.props.message.Data.datetime}
						/>
					</div>
				</div>

				<div
					style={{
						flexDirection: 'row',
						borderRadius: 5,
						width: 300,
						height: 300,
					}}
				>
					{(this.props.message.Data.sender === this.props.user.doqid
						&& !this.props.message.Estado.uploaded
						&& !this.state.loading) ? (
							<div
								style={{
									display: 'flex',
									width: 300,
									borderRadius: 5,
									justifyContent: 'center',
									alignItems: 'center',
									height: 280,
									backgroundColor: '#000',
								}}
							>

								<div
									style={{
										width: 300,
										borderRadius: 5,
										position: 'absolute',
										height: 280,
										backgroundImage: `url(${this.props.message.Data.mensaje})`,
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										cursor: 'pointer'
									}}
								/>
								<IconButton
									onClick={() => {
										this.uploadImage();
									}}>

									<CloudUploadIcon
										style={{
											color: '#fff',
											fontSize: 35,
										}} />

								</IconButton>

							</div>
						) : (
							null
						)}

					{(this.props.message.Data.sender === this.props.user.doqid
						&& !this.props.message.Estado.uploaded
						&& this.state.loading) ? (
							<div
								style={{
									display: 'flex',
									width: 300,
									borderRadius: 5,
									justifyContent: 'center',
									alignItems: 'center',
									height: 280,
									backgroundColor: '#000',
								}}
							>
								<	CircularProgress
									size={30}
									color="#fff" />
								<div
									style={{
										width: 300,
										borderRadius: 5,
										position: 'absolute',
										height: 280,
										backgroundImage: `url(${this.props.message.Data.mensaje})`,
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										cursor: 'pointer'
									}}
								/>
							</div>
						) : null}

					{(this.props.message.MetaData && !this.state.uri) ? (
						<div
							style={{
								display: 'flex',
								width: 300,
								borderRadius: 5,
								justifyContent: 'center',
								alignItems: 'center',
								height: 280,
								backgroundColor: '#000',
							}}
						>
							<	CircularProgress
								size={30}
								color="#fff"
								style={{
									zIndex: 10,
								}} />
						</div>
					) : null}

					{(this.props.message.MetaData && this.state.uri) ? (

						<div
							style={{
								width: 300,
								borderRadius: 5,
								height: 280,
								backgroundImage: this.state.uri ? `url(${this.state.uri})` : 'none',
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								cursor: 'pointer'
							}}

							onClick={this.openFileViewer}
						>
						</div>
					) : null}
				</div>
			</ChatMessageHOC>
		);
	}
}


const mapDispatchToProps = (dispatch) => ({
	openFileViewer: (props) => dispatch(FileViewerActions.open(props)),
});

export default connect(null, mapDispatchToProps)(ChatImageMessageItem);