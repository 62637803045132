import React, { PureComponent } from 'react';
import moment from 'moment';
import { ChatMessageStatus, ChatMessageTime } from 'components/Chat/components/metadata';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import { ChatMessageHOC } from 'components/Chat/components/messages-types/_/index';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
	CircularProgress,
	IconButton,
	Avatar,
	Typography
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { v1 as uuid } from 'uuid'
import MicIcon from '@material-ui/icons/Mic';
import PauseIcon from '@material-ui/icons/Pause';
import FileCacheDB from 'services/dexiedb';
import Store from 'redux/index';

require('moment-duration-format');
class ChatAudioMessageItem extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isPlaying: false,
			duration: null,
			uri: null,
			offset: 0,
			preparePlayer: true
		};
	}

	async componentDidMount() {
		if (this.props.message.Data.sender === this.props.user.doqid && !this.props.message.Estado.uploaded) {
			this.uploadAudio();
		}

		if (this.props.message.Data.sender !== this.props.user.doqid) {
			if (!this.props.message.Estado.leido) {
				try {
					const updates = {};

					updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/Estado/leido`] = true;

					// Set message as received and readed.
					await firebase.database().ref().update(updates);
				} catch (e) {
					// Nothing to do ...
				}
			}
		}

		this.props.observable.subscribe((data) => {
			switch (data.type) {
				case 'recording-audio-start':
					if (this.state.isPlaying) {
						this.setState({
							isPlaying: false,
						}, () => {
							this.player.stop();
						});
					}
					break;
				case 'playing-audio':
					if (data.payload.id !== this.props.messageKey) {
						this.setState({
							isPlaying: false,
						}, () => {
							if (this.player) {
								this.player.pause();
							}
						});
					}
					break;
				default:
				// Nothing to do ...
			}
		});

		if (this.props.message.MetaData) {
			this.preparePlayer(this.props.message.MetaData.url)
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.message.MetaData) {
			if (nextProps.message.MetaData.url !== prevState.uri) {
				return { uri: nextProps.message.MetaData.url };
			}
			else return null;
		}
		return null
	}

	componentDidUpdate(prevProps) {

		if (prevProps.message.MetaData) {
			if (prevProps.message.MetaData !== this.props.message.MetaData) {
				console.log(this.props.message.MetaData.url)
				console.log(this.state.uri)
				this.preparePlayer(this.props.message.MetaData.url)
			}
		}
	}

	onPressPlay = () => {

		if (!this.state.duration) return;

		if (this.props.message.Data.sender !== this.props.user.doqid
			&& !this.props.message.Estado.reproducido) {
			const updates = {};

			// Set message as heared
			updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/Estado/reproducido`] = true;

			// Execute the update
			firebase.database().ref().update(updates);
		}
		this.player.play()

		this.setState({
			isPlaying: true,
		}, async () => {
			this.props.observable.next({
				type: 'playing-audio',
				payload: {
					id: this.props.messageKey,
				},
			});
		});
	}

	onPressPause = () => {
		// Pause the audio
		this.player.pause();

		this.setState({
			isPlaying: false,
		});
	}

	uploadAudio = () => {
		this.setState({
			loading: true,
		});
		new FileCacheDB().get({
			id: this.props.message.Data.mensaje
		})
			.then(res => {
				if (!!res) {
					const ref = firebase.storage().ref(`/consultas/${this.props.chatId}/${this.props.message.Data.sender}/audios/${uuid()}.mp4`)
					console.log(res.value)
					ref
						.put(res.value, {
							cacheControl: 'max-age=31536000',
							contentType: 'audio/mp4',
						})
						.then(() => ref.getDownloadURL())
						.then((url) => {
							const updates = {};

							updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/MetaData/url`] = url;
							updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/Estado/uploaded`] = true;

							firebase.database().ref().update(updates)
								.then(() => {
									this.timeoutA = setTimeout(() => {
										this.setState({
											loading: false,
										});
									}, 1000);
								});
						})

						.catch((err) => {
							this.setState({
								loading: false,
							});
						});
				}
			})
			.catch(() => {
				this.setState({
					loading: false,
				});
				Store.dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: 'Ocurrió un problema al subir el archivo, intentelo nuevamente mas tarde o con otro navegador',
					}
				})
			})

	}

	createAudioDurationFormat() {
		return this.duration.asSeconds() < 1 ? "--:--" : "mm:ss";
	}

	preparePlayer(uri) {
		this.player = new Audio(uri)

		/*
		this is due to a Chrome and Safari error while trying to get the duration
		*************************************************************************/
		this.player.load()
		this.player.currentTime = 24 * 60 * 60;
		this.player.volume = 0
		this.player.play();
		/************************************************************************/

		//prepare player
		this.player.onloadeddata = (e) => {
			this.setState({
				loading: false,
				duration: moment
					.duration(this.player.duration, 'seconds')
					.format(this.createAudioDurationFormat, {
						trim: false,
					})
			})
		}

		this.player.ontimeupdate = (e) => {
			let current = this.player.currentTime;
			let percent = (current / this.player.duration) * 100;
			if (this.state.isPlaying) {
				this.player.volume = 1
				this.setState({
					offset: percent,
					duration: moment.duration(current, 'seconds').format('mm:ss', {
						trim: false,
					})
				})
			}
		}
		this.player.onended = (e) => {
			this.setState({
				isPlaying: false,
				offset: 0,
				duration: moment.duration(this.player.duration, 'seconds').format(this.createAudioDurationFormat, {
					trim: false,
				})
			})
		}
	}

	render() {
		if (this.props.message.Data.sender !== this.props.user.doqid
			&& !this.props.message.Estado.uploaded) {
			return null;
		}

		return (
			<ChatMessageHOC {...this.props} type="audio">
				<div
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'center',
						justifyContent: 'flex-end',
						position: 'absolute',
						bottom: 2,
						right: 10,
						zIndex: 100,
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							height: 18,
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<ChatMessageStatus
							me={this.props.me}
							status={this.props.message.Estado}
							uploaded={this.props.message.Estado.uploaded}
							hideMessageReadedStatus={this.props.hideReadedStatus}
						/>

						<ChatMessageTime
							time={this.props.message.Data.datetime}
						/>
					</div>
				</div>

				<div>
					{(this.state.loading && !this.props.message.Estado.uploaded) ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								minHeight: 40,
							}}
						>
							<div
								style={{
									display: 'flex',
									marginRight: 12,
									marginLeft: 12,
									marginTop: 9,
									marginBottom: 9,
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<	CircularProgress
									size={30} />
							</div>

							<div
								style={{
									display: 'flex',
									width: 120,
									height: 12,
									alignSelf: 'center',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'relative'
								}}
							>
								<div
									style={{
										width: 160,
										height: 2,
										backgroundColor: '#6d6d6d50',
										position: 'relative'
									}}
								>
									<div
										style={{
											position: 'absolute',
											left: 0,
											top: -4,
											width: 10,
											height: 10,
											backgroundColor: '#6d6d6d',
											borderRadius: '50%',
										}}
									/>
								</div>
							</div>
						</div>
					) : (
							null
						)}

					{(!this.state.loading && !this.props.message.Estado.uploaded) ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								minHeight: 40,
							}}
						>
							<IconButton
								onPress={this.uploadAudio}>
								<CloudUploadIcon
									style={{
										color: '#6d6d6d',
										fontSize: 30,
									}} />
							</IconButton>
							<div
								style={{
									display: 'flex',
									width: 120,
									height: 12,
									alignSelf: 'center',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'relative'
								}}
							>
								<div
									style={{
										width: 160,
										height: 2,
										backgroundColor: '#6d6d6d50',
										position: 'relative'
									}}
								>
									<div
										style={{
											position: 'absolute',
											left: 0,
											top: -4,
											width: 10,
											height: 10,
											backgroundColor: '#6d6d6d',
											borderRadius: '50%',
										}}
									/>
								</div>
							</div>
						</div>
					) : (
							null
						)}

					{(this.props.message.MetaData
						&& !this.state.isPlaying) ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									minHeight: 40,
								}}
							>
								<IconButton
									onClick={this.onPressPlay}
									style={{
										marginRight: 5
									}}
								>
									<PlayArrowIcon
										style={{
											color: '#6d6d6d',
											fontSize: 25,
										}}
									/>
								</IconButton>

								<div
									style={{
										display: 'flex',
										width: 120,
										height: 12,
										alignSelf: 'center',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'relative'
									}}
								>
									<div
										style={{
											width: 160,
											height: 2,
											backgroundColor: '#6d6d6d50',
											position: 'relative'
										}}
									>

										<div
											style={{
												position: 'absolute',
												top: -4,
												width: 10,
												height: 10,
												borderRadius: '50%',
												left: `${this.state.offset}%`,
												backgroundColor: this.props.message.Data.sender !== this.props.user.doqid ?
													this.props.message.Estado.reproducido ? '#04A9F4' : '#0ED161' : '#6d6d6d',
											}}
										/>
									</div>
									<Typography
										style={{
											position: 'absolute',
											top: 13,
											left: 0,
											fontSize: 10,
											color: '#a2a2a2',
										}}
									>
										{this.state.duration}
									</Typography>
								</div>
							</div>
						) : (
							null
						)}

					{(this.props.message.MetaData
						&& this.state.isPlaying) ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									minHeight: 40,
								}}
							>
								<IconButton
									onClick={this.onPressPause}
									style={{
										marginRight: 5
									}}
								>
									<PauseIcon
										style={{
											color: '#6d6d6d',
											fontSize: 25,
											height: 25,
										}}
									/>
								</IconButton>

								<div
									style={{
										display: 'flex',
										width: 120,
										height: 12,
										alignSelf: 'center',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'relative'
									}}
								>
									<div
										style={{
											width: 160,
											height: 2,
											backgroundColor: '#6d6d6d50',
											position: 'relative',
										}}
									>
										<div
											style={{
												position: 'absolute',
												top: -4,
												width: 10,
												height: 10,
												borderRadius: '50%',
												left: `${this.state.offset}%`,
												backgroundColor: this.props.message.Data.sender !== this.props.user.doqid ?
													this.props.message.Estado.reproducido ? '#04A9F4' : '#0ED161' : '#6d6d6d',
											}}
										/>
									</div>

									<Typography
										style={{
											position: 'absolute',
											top: 13,
											left: 0,
											fontSize: 10,
											color: '#a2a2a2',
										}}
									>
										{this.state.duration}
									</Typography>
								</div>
							</div>
						) : (
							null
						)}
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						position: 'relative'
					}}
				>
					<div
						style={{
							width: 40,
							height: 40,
							borderRadius: 20,
							display: 'flex',
							overflow: 'hidden',
							alignItems: 'center',
							marginRight: 15,
						}}
					>
						<Avatar
							style={{
								height: 45,
								width: 45,
								borderRadius: 20,
								backgroundColor: '#f1f1f1',
							}}
							src={this.props.thumbnailId} />
					</div>

					<div
						style={
							{
								color: '#365D94',
								fontSize: 20,
								marginRight: 20,
								position: 'absolute',
								right: -10,
								bottom: 0,
								padding: 2,
								width: 18,
								height: 18,
								borderRadius: '50%',
								backgroundColor: (this.props.me) ? '#e3e9f0' : '#fff',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
					>
						<MicIcon
							style={{
								fontSize: 17,
								color: this.props.message.Data.sender !== this.props.user.doqid ?
									this.props.message.Estado.reproducido ? '#04A9F4' : '#0ED161' : '#6d6d6d',
							}}
						/>
					</div>
				</div>
			</ChatMessageHOC >
		);
	}
}

export default ChatAudioMessageItem;
