import React from "react";
import {
    Typography,
    Badge,
    Avatar,
    Divider,
    CircularProgress
} from "@material-ui/core";
import { ReferencesActions } from 'redux/actions';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import FirebaseListenersRegistry from 'services/FirebaseListenersRegistry';
import { CaseStatus } from 'constants/index.js';

class CaseItem extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            badge: null,
            formattedLastMessageTime: props.openCase ? null : moment(this.props.data.FechaHora).format('DD-MM-YY')
        }
    }


    componentDidMount() {
        // Only we turn on bagde listener for open cases
        // and recent closed cases

        if (this.props.data.SyncOnline) {
            const refA = firebase.database().ref(`Consultas/Chats/${this.props.chatId}/Estado/Badges/${this.props.context.user.doqid}`);

            this.badgeListenerA = refA.on('value', (snapshot) => {
                const data = snapshot.val()

                if (snapshot && data !== null) {
                    this.setState({ badge: data });
                }
            });
        }

        FirebaseListenersRegistry.add(`Consultas/Chats/${this.props.chatId}/Estado/Badges/${this.props.context.user.doqid}`);
        // Only we turn on last message time listener
        // for the open cases  
        if (this.props.openCase) {
            const refB = firebase.database().ref(`Consultas/Chats/${this.props.chatId}/Estado/Propiedades/FechaHoraUltimoMensajeChat`)

            this.badgeListenerB = refB.on('value', (snapshot) => {
                if (snapshot && snapshot.val() !== null) {
                    if (this.props.onUpdateLastMessageTime) {
                        this.setState({
                            lastMessageTime: snapshot.val(),
                            formattedLastMessageTime: moment(snapshot.val()).calendar(null, {
                                sameDay: 'HH:mm',
                                lastDay: '[ayer]',
                                lastWeek: 'DD/MM/YYYY',
                                sameElse: 'DD/MM/YYYY'
                            })
                        });
                        this.props.onUpdateLastMessageTime(snapshot.val(), this.props.chatId);
                    }
                }
            });

            FirebaseListenersRegistry.add(`Consultas/Chats/${this.props.chatId}/Estado/Propiedades/FechaHoraUltimoMensajeChat`);
        }

        this.props.fetchUserData(this.props.context.user.doqid);
        this.props.fetchUserData(this.props.data.PacienteId);
        this.props.fetchUserData(this.props.data.Participantes[0]);

    }

    handleItemClick = () => {
        this.props.onCaseItemClick(this.props.chatId);
    };

    componentWillUnmount() {

        if (this.badgeListenerA) {
            firebase.database().ref(`Consultas/Chats/${this.props.chatId}/Estado/Badges/${this.props.context.user.doqid}`).off('value', this.badgeListenerA);
        }

        if (this.badgeListenerB) {
            firebase.database().ref(`Consultas/Chats/${this.props.chatId}/Estado/Propiedades/FechaHoraUltimoMensajeChat`).off('value', this.badgeListenerB);
        }
    }


    render() {
        const { classes, data } = this.props;
        if (this.props.chat && this.props.chat.EstadoConsulta === CaseStatus.DISCARDED) {
            return null;
        }

        return (
            <>
                <div
                    className={classes.root}
                    style={{
                        backgroundColor: this.props.activeChat === this.props.chatId ? 'rgba(0,0,0,.04)' : '#fff'
                    }}
                    onClick={() => this.handleItemClick()}>
                    <div
                        className={classes.avatarContainer}>
                        {this.props.patient ?
                            <Avatar
                                className={classes.avatar}
                                src={this.props.patient.ThumbnailId} />
                            :
                            <CircularProgress />
                        }
                    </div>
                    <div
                        className={classes.infoContainer}>
                        <Typography
                            variant="h6">
                            {this.props.patient ?
                                `${this.props.patient.Nombre} ${this.props.patient.Apellido}`
                                :
                                'Cargando..'}</Typography>
                        <Typography
                            variant="body2">{data.Titulo}</Typography>
                    </div>
                    <div
                        className={classes.hourContainer}>
                        <Typography
                            variant="body2">    {this.state.formattedLastMessageTime}</Typography>

                    </div>
                    <div
                        className={classes.badgeContainer}>
                        {((this.props.chat.TipoBadge === undefined || this.props.chat.TipoBadge === 0) && !this.props.chat.TextoBadge) ? (
                            (this.props.chat.ConVista === false) ? (
                                <Badge
                                    style={{
                                        left: 20
                                    }}
                                    color="primary"
                                    badgeContent="Nuevo" />)
                                :
                                (this.state.badge && this.state.badge !== 0) ? (
                                    <Badge
                                        style={{
                                            left: 20
                                        }}
                                        color="secondary"
                                        badgeContent={(this.state.badge > 99) ? '99+' : this.state.badge} />
                                )
                                    :
                                    null)
                            :
                            (<Badge
                                color="secondary"
                                style={{
                                    left: 20
                                }}
                                badgeContent={this.props.chat.TextoBadge} />)}
                    </div>
                </div>
                <Divider />
            </>
        );
    }
}


const mapStateToProps = (state, props) => {

    let user = undefined;
    let patient = undefined;

    if (props.data && props.data.Participantes) {
        user = state.references.users[props.data.Participantes[0]]
    }
    if (props.data && props.data.PacienteId) {
        patient = state.references.users[props.data.PacienteId]
    }
    return {
        user,
        patient,
        context: state.context,
        activeChat: state.chat.activeChat,
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchUserData: doqid => dispatch(
        ReferencesActions.fetchUserData(doqid),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseItem);
