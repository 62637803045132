const defaultState = {
    login: false,
    success: false,
    customToken: null,
    qrCode: null,
    expiredDate: null,
    path: null,
    pathId: null,
    code: null,
    userDataLoading: false,
    loginState: {
        step1: {
            label: 'Obteniendo datos',
            verify: false
        },
        step2: {
            label: 'Obteniendo código QR',
            verify: false
        },
        step3: {
            label: 'Iniciando sesión del usuario',
            verify: false
        },
    }
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'LOGIN_LOADING':
            return Object.assign({}, state, {
                loading: true,
            })

        case 'GET_QR_WEB_LOGIN_SUCCESS':
            return Object.assign({}, state, {
                customToken: action.payload.customToken,
                qrCode: action.payload.qrCode,
                expiredDate: action.payload.expiredDate,
                path: action.payload.path,
                pathId: action.payload.pathId,
                loading: false,
                success: true,

            })
        case 'LOGIN_STATE_FIRST_STEP_UPDATE':
            return Object.assign({}, state, {
                loginState: {
                    ...state.loginState,
                    step1: {
                        ...state.loginState.step1,
                        verify: true
                    }
                }
            })

        case 'LOGIN_STATE_SECOND_STEP_UPDATE':
            return Object.assign({}, state, {
                loginState: {
                    ...state.loginState,
                    step2: {
                        ...state.loginState.step2,
                        verify: true
                    }
                }
            })

        case 'LOGIN_STATE_THIRD_STEP_UPDATE':
            return Object.assign({}, state, {
                loginState: {
                    ...state.loginState,
                    step3: {
                        ...state.loginState.step3,
                        verify: true
                    }
                }
            })

        case 'GET_USER_DATA_LOADING':
            return Object.assign({}, state, {
                userDataLoading: true
            })

        case 'GET_USER_DATA_SUCCESS':
            return Object.assign({}, state, {
                userDataLoading: false
            })

        case 'LOGIN_RESET_STATE':
            return Object.assign({}, state, {
                ...defaultState,
            })

        default:
            return state;
    }
}
