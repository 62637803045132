// @flow

function open(props) {
	return {
		type: 'FILE_VIEWER_OPEN',
		payload: {
			props
		}
	}
}

function close() {
	return {
		type: 'FILE_VIEWER_CLOSE',
	}
}

export const FileViewerActions = {
	open,
	close
};
