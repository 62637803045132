// @flow
import RestClient from 'http/RestClient';

export default class DoctorRestClient extends RestClient {
	constructor(useCredentials: boolean, refreshContext: ?boolean) {
		super(
			undefined,
			useCredentials,
			refreshContext,
		);
	}

	/* 
	* Close case
	*/
	closeCase(params) {
		const url = `${this.baseURL}/consultas/cerrarconsulta`
		return this.instance.post(url, {
			noCorresponde: params.notCorresponds,
			consultaId: params.consultaId,
			bonificada: params.bonus,
		});
	}

}
