import React, { PureComponent } from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ScheduleIcon from '@material-ui/icons/Schedule';

class ChatMessageStatus extends PureComponent {
	/* 
  TODO: montar sonido de notificacion acá
componentWillReceiveProps(nextProps) {
	  if (!this.props.status.enviado && nextProps.status.enviado && nextProps.uploaded && nextProps.me) {
		  this.send = new Sound('mensaje_enviado.mp3', Sound.MAIN_BUNDLE, () => {
			  this.send.setVolume(1);
			  this.send.play();
		  });
	  }
  } 
 
  componentWillUnmount() {
	  if (this.send && typeof this.send.release === 'function') {
		  this.send.release();
	  }
  }
*/
	render() {
		return (
			<div>
				{(this.props.me &&
					this.props.uploaded &&
					this.props.status.enviado &&
					this.props.status.recibido &&
					this.props.status.leido &&
					!this.props.hideMessageReadedStatus && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginLeft: 8,
								width: 15,
								height: 19,
							}}
						>
							<DoneAllIcon
								style={{
									color: '#28B6F5',
									fontSize: 16,
								}} />
						</div>
					))}

				{(this.props.me &&
					this.props.uploaded &&
					this.props.status.enviado &&
					this.props.status.recibido &&
					(!this.props.status.leido || this.props.hideMessageReadedStatus) && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginLeft: 8,
								width: 15,
								height: 19,
							}}
						>
							<DoneAllIcon
								style={{
									color: '#a2a2a2',
									fontSize: 16,
								}} />
						</div>
					))}


				{(this.props.me &&
					(!this.props.status.enviado || !this.props.uploaded) && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginLeft: 8,
								width: 15,
								height: 16,
							}}
						>
							<ScheduleIcon
								style={{
									fontSize: 15,
									color: '#a2a2a2',
								}}
							/>
						</div>
					))}
			</div>
		);
	}
}

export default ChatMessageStatus;
