
const styles = theme => ({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
    },
});


export default styles