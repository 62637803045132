import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ChatActions, ClosedCasesActions } from "redux/actions";
import {
    Box,
    Typography,
    CircularProgress,
} from '@material-ui/core';
import CaseItem from 'components/LeftSide/CaseItem';
import Scrollbar from "react-scrollbars-custom";

class ClosedCases extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };

    }

    componentDidMount() {
        this.props.startClosedCasesListener(this.props.user.doqid)
        if (!(this.props.paginatedClosedCases.length > 0)) {
            this.props.fetchClosedCases(
                this.props.user.doqid
            )
        }
    }

    onCaseItemClick = (chatId) => {
        // Set this chat as active
        this.props.setActiveChat(chatId);
    }

    onScrollHandle = (scrollValues) => {
        if (scrollValues.scrollTop > scrollValues.scrollHeight - scrollValues.clientHeight) {
            //Scroll has reached the bottom
            this.props.fetchMoreClosedCases(this.props.user.doqid)
        }
    }

    render() {
        const { classes, loadingCases, paginatedClosedCases } = this.props;

        if (loadingCases) {
            return (
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        position: 'relative',
                        alignContent: 'center',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        alignItems: 'center'
                    }}>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <Scrollbar
                ref={(ref) => {
                    this.scrollbar = ref;
                }}
                style={{
                    height: '100%',
                    position: 'relative',
                    width: '100%',
                }}
                onScroll={this.onScrollHandle}
                contentProps={{
                    renderer: props => {
                        const { elementRef, ...restProps } = props;
                        return <div
                            {...restProps}
                            ref={elementRef}
                            className="Content"
                            style={{
                                display: 'flex',
                                position: 'relative'
                            }}
                        />;
                    }
                }}>
                {paginatedClosedCases.length === 0 || !paginatedClosedCases ?
                    (<Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '100%'
                        }}>

                        <img
                            src='/static/images/chat.png'
                            alt=''
                            style={{
                                opacity: 0.1
                            }} />

                        <Typography
                            variant='subtitle'>No tienes consultas cerradas</Typography>
                    </Box>)
                    :
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}>
                        {Object.keys(paginatedClosedCases || {})
                            .map((keyName, i) => (
                                <CaseItem
                                    key={i}
                                    chatId={paginatedClosedCases[keyName].key}
                                    chat={paginatedClosedCases[keyName]}
                                    data={paginatedClosedCases[keyName]}
                                    onCaseItemClick={this.onCaseItemClick} />))}
                    </div>}
                {(this.props.fetchingMoreClosedCases && this.props.moreClosedCasesFlag) ?
                    <div
                        className={classes.fetchMoreCasesContainer}>
                        <div
                            className={classes.fetchMoreCasesFlag}>
                            <Typography
                                style={{
                                    color: '#fff'
                                }}>
                                Cargando mas casos...
                                </Typography>
                        </div>
                    </div>
                    :
                    null}

            </Scrollbar>
        );
    }
}

const mapStateToProps = (state) => ({
    paginatedClosedCases: state.closedCases.paginatedClosedCases || [],
    closedCases: state.closedCases.closedCases,
    loadingCases: state.closedCases.loadingCases,
    fetchingMoreClosedCases: state.closedCases.fetchingMoreClosedCases,
    moreClosedCasesFlag: state.closedCases.moreClosedCasesFlag,
    user: state.context.user,
});

const mapDispatchToProps = (dispatch) => ({
    fetchClosedCases: (doqid) => dispatch(ClosedCasesActions.fetchClosedCases(doqid)),
    startClosedCasesListener: (doqid) => dispatch(ClosedCasesActions.startClosedCasesListener(doqid)),
    fetchMoreClosedCases: (doqid) => dispatch(ClosedCasesActions.fetchMoreClosedCases(doqid)),
    setActiveChat: (chatId) => dispatch(ChatActions.setActiveChat(chatId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosedCases);
