const styles = theme => ({
  notificationsOff: {
    backgroundColor: '#9DE1FE',
    color: '#fff'
  },
  notificationsActive: {
    backgroundColor: '#A4FE9D',
    color: '#fff'
  },
  modal: {
    color: '#fff',
    position: 'absolute',
    width: 400,    
    border: "none",  
    boxShadow: "none",
    padding: theme.spacing(2, 4, 3),
  },
});

export default styles