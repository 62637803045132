import UserRestClient from 'http/resources/UserRestClient';
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/messaging'
/**
 *  Send the FCM token to the
 *  backend.
 *
 *  @returns {string} - The FCM token
 */
function updateFCMToken(token) {
    return async dispatch => {
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
            await firebase.messaging()
                .getToken()
                .then((currentToken) => {
                    if (currentToken) {
                        new UserRestClient(true).updateFCMToken({
                            token: currentToken
                        })
                            .then((res) => {
                                // Nothing to do ...
                            })

                            .catch((error) => {
                                // Nothing to do ...
                            })
                    } else {
                        dispatch({
                            type: 'UI_ALERT_SET_ERROR',
                            payload: {
                                error: 'Ocurrió un problema con las notificaciones. Intente nuevamente refrescando la página',
                            }
                        });
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: 'UI_ALERT_SET_ERROR',
                        payload: {
                            error: 'Ocurrió un problema con las notificaciones. Intente nuevamente refrescando la página',
                        }
                    });
                });
    };
}

export const NotificationActions = {
    updateFCMToken,
};
