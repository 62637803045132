import { combineReducers } from 'redux'
import login from './login'
import context from './context'
import chat from './chat'
import ui from './ui'
import references from './references'
import closedCases from './closed-cases'
import fileviewer from './file-viewer'
import closeCase from './close-case'
const rootReducer = combineReducers({
    login,
    context,
    chat,
    ui,
    references,
    closedCases,
    fileviewer,
    closeCase
})

export default rootReducer;