export const CaseStatus = {
	UNDEFINED: 0,
	PROCESSING_OPEN_CASE_PAYMENT: 5,
	PROCESSING_CLOSE_CASE_PAYMENT: 6,
	PENDING: 10,
	OPEN: 15,
	OPEN_CASE_PAYMENT_ERROR: 20,
	CLOSE_CASE_PAYMENT_ERROR: 21,
	CLOSED: 25,
	FINISHED: 30,
	DISCARDED: 40,
};

export const CaseTypes = {
    CONSULTATION: 1,
    MESSAGE: 2,
    RECIPE: 3,
};