import Store from "redux/index";
import firebase from 'firebase/app'
import 'firebase/messaging'

const registerServiceWorker = () => {
  if ("serviceWorker" in navigator &&
    firebase.messaging.isSupported()) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function (registration) {
        //nothing todo..
      })
      .catch(function (err) {
        Store.dispatch({
          type: 'UI_ALERT_SET_ERROR',
          payload: {
            error: 'Ocurrió un problema al activar las notificaciones de escritorio, intentelo nuevamente mas tarde o con otro navegador',
          }
        })
      });
  }
};

export { registerServiceWorker };
