export class ApiNonFatalError extends Error {
    constructor(response, restart) {
        if (response
                && response.data
                && response.data.errors instanceof Array
                && response.data.errors[0]
                && response.data.errors[0].message
        ) {
            super(response.data.errors[0].message);
        } else {
            super('A non fatal error was received from de API');
        }

        this.name = "ApiNonFatalError";
        this.info = response;
        this.restart = restart;
    }
}