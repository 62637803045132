const styles = theme => ({
  root: {
    width: '100%',
    //backgroundColor: theme.palette.secondary.main
    borderTopLeftRadius: 4,
  },
  actionBtn: {
    color: '#fff'
  }
});


export default styles