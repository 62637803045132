import React from "react";
import Modal from "@material-ui/core/Modal";
import NotificationsOffOutlined from "@material-ui/icons/NotificationsOffOutlined";
import NotificationsOutlined from "@material-ui/icons/NotificationsOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import { NotificationActions } from 'redux/actions';

import { connect } from "react-redux";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function NotificationsPermission(props) {
    const { classes, updateFCMToken } = props;
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = async () => {
        setOpen(true);
        await updateFCMToken()
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const bodyModal = (
        <div
            style={modalStyle}
            className={classes.modal}>
            <h2 id="simple-modal-title">
                <NotificationsOutlined fontSize="large" />
                Permitir mostrar notificaciones
            </h2>
            <p id="simple-modal-description">
                Haz clic en "Permitir" arriba para recibir notificaciones de mensajes
                nuevos.
            </p>
        </div>);

    const notificationAlertOff = (
        <List className={classes.notificationsOff} dense="true">
            <ListItem button onClick={handleOpen}>
                <ListItemIcon>
                    <NotificationsOffOutlined />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            variant="h5"
                            style={{ color: "#000" }}>
                            Recibe notificaciones de mensajes nuevos
                        </Typography>}
                    secondary={
                        <Typography
                            variant="h6"
                            style={{ color: "#000" }}>
                            Activar notificaciones de escritorio
                        </Typography>}
                />
            </ListItem>
        </List>
    );

    return (
        <div>
            {Notification.permission === "default" && notificationAlertOff}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {bodyModal}
            </Modal>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateFCMToken: () => dispatch(NotificationActions.updateFCMToken()),
});

export default connect(null, mapDispatchToProps)(NotificationsPermission);

