import React, { PureComponent } from 'react'
import {
	ChatCaseIntroMessageItem,
	ChatInformationMessageItem,
	ChatWarningMessageItem,
	ChatErrorMessageItem,
	ChatDefaultMessageItem,
	ChatCallMessageItem,
	ChatTextMessageItem,
	ChatImageMessageItem,
	ChatVideoMessageItem,
	ChatFileMessageItem,
	ChatUnknowMessageItem,
	ChatAudioMessageItem
} from 'components/Chat/components/messages-types'
import Scrollbar from "react-scrollbars-custom";
import { Subject } from 'rxjs';
import {
	CircularProgress,
	Button
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';


class RenderChatMessages extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			newMessages: false
		}
		this.observable = new Subject();
	}

	componentDidUpdate(prevProps, prevState) {
		const { scrollValues } = this.scrollbar
		if (this.props.chat) {
			if (prevProps.chat.messages !== this.props.chat.messages) {
				if ((scrollValues.scrollTop) > (scrollValues.contentScrollHeight - scrollValues.clientHeight * 1.3)) {
					this.setState({
						newMessages: false
					})
					this.scrollbar.scrollToBottom();
				} else if (prevProps.chat.lastIndex === this.props.chat.lastIndex &&
					scrollValues.scrollTop !== 0) {
					this.setState({
						newMessages: true
					})
				}
			}
		}
		if (prevProps.chat.activeChat !== this.props.chat.activeChat) {
			this.setState({
				newMessages: false
			})
		}
	}

	onScrollHandle = (scrollValues) => {
		if (scrollValues.scrollTop === 0) {
			//Scroll has reached the top
			this.props.fetchMoreMessages()
		}
		if ((scrollValues.scrollTop) > (scrollValues.contentScrollHeight - scrollValues.clientHeight * 1.3)) {
			this.setState({
				newMessages: false
			})
		}
	}
	handleClickNewMessages = () => {
		this.scrollbar.scrollToBottom();
	}

	getThumbnail = (message) => {
		let thumbnail = '';
		if (message.Data.sender !== this.props.otherDevice) {
			if (this.props.context.user.role === '2') {
				thumbnail = this.props.patient ? this.props.patient.ThumbnailId : '';
			} else {
				thumbnail = this.props.professional ? this.props.professional.ThumbnailId : '';
			}
		} else if (this.props.context.user.role === '2') {
			thumbnail = this.props.user ? this.props.user.ThumbnailId : '';
		} else {
			thumbnail = this.props.patient ? this.props.patient.ThumbnailId : '';
		}
		return thumbnail
	}

	onRenderMessage = (message) => {
		if (!message) return;
		if (!message) return;
		if (!message.Data) return;
		if (message.Data.ownview && this.props.context.user.doqid !== message.Data.sender) return;
		if (message.Data.type === 'initCard') {
			return (
				<ChatCaseIntroMessageItem
					key={message.key}
					message={message}
					role={this.props.context.user.role}
				/>
			)
		}
		if (message.Data.type === 'information') {
			if (message.Data.subtype === 'error') {
				return (
					<ChatErrorMessageItem
						key={message.key}
						message={message}
					/>
				);
			}

			if (message.Data.subtype === 'warning') {
				return (
					<ChatWarningMessageItem
						key={message.key}
						message={message}
					/>
				);
			}

			if (message.Data.subtype === 'information') {
				return (
					<ChatInformationMessageItem
						key={message.key}
						message={message}
					/>
				);
			}

			if (message.Data.subtype === 'llamadaencurso') {
				return (
					<ChatCallMessageItem
						key={message.key}
						message={message}
					/>
				)
			}

			return (
				<ChatDefaultMessageItem
					key={message.key}
					message={message}
				/>
			);
		}
		else if (message.Data.type === 'message') {
			return (
				<ChatTextMessageItem
					key={message.key}
					messageKey={message.key}
					chatId={this.props.chat.activeChat}
					user={this.props.context.user}
					message={message}
					me={message.Data.sender === this.props.context.user.doqid}
					hideReadedStatus={
						!this.props.currentCase.PermitirVerMensajeLeido
					}
				/>
			);
		}
		else if (message.Data.type === 'image') {
			let thumbnail = this.getThumbnail(message)
			return (
				<ChatImageMessageItem
					key={message.key}
					messageKey={message.key}
					chatId={this.props.chat.activeChat}
					user={this.props.context.user}
					message={message}
					thumbnailId={thumbnail}
					me={message.Data.sender === this.props.context.user.doqid}
					hideReadedStatus={
						!this.props.currentCase.PermitirVerMensajeLeido
					}
				/>
			);
		} else if (message.Data.type === 'video') {
			let thumbnail = this.getThumbnail(message)
			return (
				<ChatVideoMessageItem
					key={message.key}
					messageKey={message.key}
					chatId={this.props.chat.activeChat}
					user={this.props.context.user}
					message={message}
					thumbnailId={thumbnail}
					me={message.Data.sender === this.props.context.user.doqid}
					hideReadedStatus={
						!this.props.currentCase.PermitirVerMensajeLeido
					}
				/>
			);
		} else if (message.Data.type === 'file') {
			return (
				<ChatFileMessageItem
					key={message.key}
					messageKey={message.key}
					chatId={this.props.chat.activeChat}
					user={this.props.context.user}
					message={message}
					me={message.Data.sender === this.props.context.user.doqid}
					hideReadedStatus={
						!this.props.currentCase.PermitirVerMensajeLeido
					}
				/>
			)
		} else if (message.Data.type === 'ptt') {
			let thumbnail = this.getThumbnail(message)

			return (
				<ChatAudioMessageItem
					key={message.key}
					messageKey={message.key}
					chatId={this.props.chat.activeChat}
					user={this.props.context.user}
					message={message}
					observable={this.observable}
					thumbnailId={thumbnail}
					me={message.Data.sender === this.props.context.user.doqid}
					hideReadedStatus={
						!this.props.currentCase.PermitirVerMensajeLeido
					}
				/>
			);
		}

		return (
			<ChatUnknowMessageItem
				key={message.key}
			/>
		);
	}


	render() {
		return (
			<div style={{
				position: 'relative',
				height: '100%',
				width: '100%',
			}}>
				{this.props.chat.fetchingMoreMessages &&
					(<div
						style={{
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							top: 5,
							zIndex: 1000
						}}>
						<CircularProgress
							color='secondary'
							size={30} />
					</div>)}

				{this.state.newMessages &&
					(<div
						style={{
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							bottom: 5,
							zIndex: 1000
						}}>
						<Button
							variant="contained"
							color="primary"
							onClick={this.handleClickNewMessages}
							startIcon={<ArrowDownwardIcon />}
						>
							Hay nuevos mensajes
      					</Button>
					</div>)}


				<Scrollbar
					ref={(ref) => {
						this.scrollbar = ref;
					}}
					style={{
						height: '100%',
						width: '100%',
					}}
					onScroll={this.onScrollHandle}
					contentProps={{
						renderer: props => {
							const { elementRef, ...restProps } = props;
							return <div
								{...restProps}
								ref={elementRef}
								className="Content"
								style={{
									display: 'flex',
									flexDirection: 'column-reverse',
									padding: '0 20px',
									paddingBottom: 10
								}}
							/>;
						}
					}}>

					{(this.props.chat && this.props.chat.messages) &&
						this.props.chat.messages.map((message) => {
							const renderItem = this.onRenderMessage(message)
							return renderItem
						})}

				</Scrollbar >
			</div>
		)
	}
}

export default RenderChatMessages