const defaultState = {
	open: false,
	message: null,
	me: null,
	thumbnailId: null,
	type: null
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {

		case 'FILE_VIEWER_OPEN':
			return Object.assign({}, state, {
				open: true,
				...action.payload.props
			})

		case 'FILE_VIEWER_CLOSE':
			return Object.assign({}, state, {
				...defaultState
			})

		default:
			return state;
	}
}
