
const styles = theme => ({
    root: {
        marginTop: 19,
        flexGrow: 1,
        marginBottom: 40,
        maxHeight: '85vh'
        /* height: '95vh',
        maxHeight: '95vh' */
    },
});


export default styles