import React, { PureComponent } from 'react';
import moment from 'moment';
import { Typography } from '@material-ui/core';


class ChatMessageTime extends PureComponent {
    render() {
        return (
            <Typography
                style={{
                    fontSize: 9,
                    color: '#a2a2a2'
                }}>
                {moment(this.props.time).format('HH:mm')}
            </Typography>
        );
    }
}

export default ChatMessageTime;
