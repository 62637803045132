import palette from 'theme/palette';

export default {
    root: {
        textTransform: "initial",
        margin: `0px 0px`,
        minWidth: 0,
    },
    textColorPrimary: {
        color: palette.text.secondary,
        '&$selected': {
            color: palette.primary.contrastText,
        },
    },
}