const styles = theme => ({
    appTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        color: '#fff',
        padding: 25,
        paddingBottom: 5,
        '& img': {
            width: 45
        },
        '& h3': {
            fontSize: 15,
            fontWeight: '600',
            marginLeft: 12
        }
    },
    qrCode: {
        width: '100%'
    },
    root: {
        marginTop: 20,
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        margin: "auto",
    },
    stepOl: {
        paddingLeft: 50,
        '& li': {
            paddingTop: 15,
            fontFamily: 'Roboto',
            margin: 0,
            '& span': {
                padding: 5,
                borderRadius: 5,
                display: 'inline-block',
                verticalAlign: 'middle',
                backgroundColor: 'rgb(242, 242, 242)',
            }
        }
    },
    imgContainer: {
        width: 300,
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    expiredBox: {
        position: 'absolute',
        width: 300,
        height: 300,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& button': {
            backgroundColor: '#E95855',
            width: '60%',
            height: '60%',
            borderRadius: '50%',
            zIndex: 100,
            border: 'none',
            outline: 'none',
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        '& h6': {
            fontWeight: '400',
            fontSize: 12,
            maxWidth: '80%'
        }
    }
});


export default styles
