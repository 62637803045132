import React, { PureComponent } from 'react';
import { ChatMessageStatus, ChatMessageTime } from 'components/Chat/components/metadata';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage'
import { ChatMessageHOC } from 'components/Chat/components/messages-types/_/index';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
	Typography,
	CircularProgress,
	IconButton
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { v1 as uuid } from 'uuid';
import FileCacheDB from 'services/dexiedb';
import Axios from 'axios';
import Store from 'redux/index';

const fileDownload = require('js-file-download');

class ChatFileMessageItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			downloadError: false,
			uri: null,
		};
	}

	async componentDidMount() {
		if (this.props.message.Data.sender === this.props.user.doqid && !this.props.message.Estado.uploaded) {
			this.uploadFile();
		}

		if (this.props.message.Data.sender !== this.props.user.doqid) {
			if (!this.props.message.Estado.leido) {
				try {
					const updates = {};

					updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/Estado/leido`] = true;

					// Set message as received and readed.
					await firebase.database().ref().update(updates);
				} catch (e) {
					// Nothing to do ...
				}
			}
		}
	}

	async uploadFile() {
		this.setState({
			loading: true,
		});

		new FileCacheDB().get({
			id: this.props.message.Data.mensaje
		})
			.then(res => {
				if (!!res) {
					const ref = firebase.storage().ref(`/consultas/${this.props.chatId}/${this.props.message.Data.sender}/images/${uuid()}.${this.props.message.Data.extension}`)
					ref
						.put(res.value, {
							cacheControl: 'max-age=31536000',
							contentType: this.props.message.Data.mimeType,
						})
						.then(() => ref.getDownloadURL())
						.then(url => {
							const updates = {};
							updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/MetaData/url`] = url;
							updates[`Consultas/Chats/${this.props.chatId}/Mensajes/${this.props.messageKey}/Estado/uploaded`] = true;

							firebase.database().ref().update(updates)
								.then(() => {
									this.timeout = setTimeout(() => {
										this.setState({
											loading: false,
										});
									}, 1000);
								})
						})
						.catch(error => {
							setTimeout(() => {
								this.setState({
									loading: false,
								})
							}, 1000)
						})
				}
			})
			.catch(() => {
				this.setState({
					loading: false,
				});
				Store.dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: 'Ocurrió un problema al subir el archivo, intentelo nuevamente mas tarde o con otro navegador',
					}
				})
			})
	}

	downloadFile = () => {
		Axios.get(this.props.message.MetaData.url).then(res => {
			fileDownload(res.data, this.props.message.Data.filename);
		});
	}

	render() {
		if (!this.props.message) return null;
		if (!this.props.message.Data) return null;

		if (this.props.message.Data.sender !== this.props.user.doqid
			&& !this.props.message.Estado.uploaded) {
			return null;
		}
		return (
			<ChatMessageHOC {...this.props} type="audio">
				<div
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							height: 18,
							alignItems: 'center',
							justifyContent: 'flex-end',
							position: 'relative',
							top: 18,
							left: 10
						}}
					>
						<ChatMessageStatus
							me={this.props.me}
							status={this.props.message.Estado}
							uploaded={this.props.message.Estado.uploaded}
							hideMessageReadedStatus={this.props.hideReadedStatus}
						/>

						<ChatMessageTime
							time={this.props.message.Data.datetime}
						/>
					</div>
				</div>

				<div>
					{(this.props.message.Data.sender === this.props.user.doqid
						&& !this.props.message.Estado.uploaded
						&& !this.state.loading) ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									minHeight: 40,
									alignItems: 'center',
								}}
							>
								<IconButton
									onClick={() => {
										this.uploadFile();
									}}
									style={{
										marginRight: 10
									}}>
									<CloudUploadIcon
										style={{
											color: '#fff',
											fontSize: 25,
										}} />
								</IconButton>
								{getIconForMimeType(this.props.message.Data.mimeType)}

								<Typography
									noWrap
									style={{
										color: '#333',
										marginLeft: 10,
										fontSize: 15,
									}}>
									{this.props.message.Data.filename}
								</Typography>
							</div>
						) : (
							null
						)}

					{(this.props.message.Data.sender === this.props.user.doqid
						&& !this.props.message.Estado.uploaded
						&& this.state.loading) ? (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									minHeight: 40,
									alignItems: 'center',
								}}
							>
								<div
									style={{
										marginRight: 10,
										width: 34,
									}}
								>
									<	CircularProgress
										size={30}
										color="#fff" />
								</div>

								{getIconForMimeType(this.props.message.Data.mimeType)}

								<Typography
									noWrap
									style={{
										color: '#333',
										marginLeft: 10,
										fontSize: 15,
									}}>
									{this.props.message.Data.filename}
								</Typography>
							</div>
						) : null}

					{(this.props.message.MetaData && this.props.message.MetaData.url) ? (
						<div
							style={{
								display: 'flex',
								width: 250,
								borderRadius: 5,
								alignItems: 'center',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							{getIconForMimeType(this.props.message.Data.mimeType)}
							<Typography
								noWrap
								style={{
									color: '#333',
									marginLeft: 10,
									fontSize: 15,
								}}>
								{this.props.message.Data.filename}
							</Typography>

							<IconButton
								style={{
									marginLeft: 10,
								}}
								onClick={this.downloadFile}>
								<GetAppIcon />
							</IconButton>
						</div>
					) : null}
				</div>
			</ChatMessageHOC>
		)
	}
}

const getIconForMimeType = (mimeType) => {
	switch (mimeType) {
		case 'application/pdf':
			return (
				<PictureAsPdfIcon
					style={{
						color: 'red',
						fontSize: 20,
					}}
				/>
			)
		default:
			return (
				<DescriptionIcon
					style={{
						color: 'red',
						fontSize: 20,
					}}
				/>
			)
	}
}

export default ChatFileMessageItem;
