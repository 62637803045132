import Dexie from 'dexie';


export default class FileCacheDB {
	constructor() {
		this.FileCacheDB = new Dexie('file-cache-db')
		this.FileCacheDB.version(1).stores({
			files: 'id,value'
		})
	}

	add(file) {
		return this.FileCacheDB.files.add(file)
	}

	get(id) {
		return this.FileCacheDB.files.get(id)
	}
}