const styles = theme => ({
	container: {
		width: '100vw',
		height: '100vh',
		backgroundColor: theme.palette.primary.main
	},
	appTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		alignContent: 'center',
		color: '#fff',
		padding: 25,
		paddingBottom: 5,
		'& img': {
			width: 45
		},
		'& h3': {
			fontSize: 15,
			fontWeight: '600',
			marginLeft: 12
		}
	},
	contentContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		marginTop: 100
	},
});


export default styles
