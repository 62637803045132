import palette from 'theme/palette';

export default {
    root: {
        backgroundColor: palette.secondary.main,
    },
    title: {
        color: palette.secondary.contrastText
    },
    action: {
        color: palette.secondary.contrastText
    }

};
