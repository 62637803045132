import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRedirect = ({ from = '/*', to, user, ...rest }) => (
    <Route
        {...rest}
        render={() =>
            user ? <Redirect to={to} /> : <Redirect to="/login" />
        }
    />
);

export default connect(state => ({ user: state.context.user }))(AuthRedirect);