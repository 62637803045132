
export default {
	root: {
		fontWeight: 'normal',
		fontSize: 14,
		color: '#999',
		backgroundColor: '#fff',
		borderColor: '#fff',
		borderRadius: 25,
		'& label.Mui-focused': {
			color: '#fff',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#fff',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#fff',
				borderRadius: 25,
			},
			'&:hover fieldset': {
				borderColor: '#fff',
				borderRadius: 25,
			},
			'&.Mui-focused fieldset': {
				borderColor: '#fff',
			},
			'&.Mui-disabled fieldset': {
				borderColor: '#fff',
			},
		},
	},
}