import React, { PureComponent } from 'react';
import { ChatMessageHOC } from 'components/Chat/components/messages-types/_/index';
import { Typography } from '@material-ui/core';
import { ChatMessageStatus, ChatMessageTime } from 'components/Chat/components/metadata';


class ChatTextMessageItem extends PureComponent {

	render() {
		return (
			<ChatMessageHOC {...this.props} type="text">
				<div
					style={{
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'flex-end',
						justifyContent: 'flex-end',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse',
							height: 18,
							alignItems: 'center',
							justifyContent: 'flex-end',
							position: 'relative',
							top: 6,
							left: 10
						}}
					>
						<ChatMessageStatus
							me={this.props.me}
							status={this.props.message.Estado}
							hideMessageReadedStatus={this.props.hideReadedStatus}
							uploaded
						/>

						<ChatMessageTime
							time={this.props.message.Data.datetime}
						/>
					</div>
				</div>

				<div>

					<Typography
						style={{
							fontSize: 15,
						}}>
						{this.props.message.Data.mensaje}
					</Typography>
				</div>
			</ChatMessageHOC >
		);
	}
}

export default ChatTextMessageItem;
