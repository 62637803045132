import { ApiNonFatalError } from './error-types';

const defaultErrorMessage = (
    'Ha ocurrido un error inesperado.\n\n' +
    'Hemos recibido la información de lo ocurrido y ' +
    'en breve estaremos trabajando para solucionarlo.\n\n' +
    'Si el problema persiste comuníquese con nosotros enviando un email a info@doqchat.com'
);

export default class ErrorManager {
    static toMessage(error) {
        if (error instanceof ApiNonFatalError) {
            return error.info.data.errors[0].message
        }

        if (error instanceof Error) {
            if (error.message === 'error-silent') {
                return null;
            }

            if (error.code) {
                return defaultErrorMessage;
            }

            return (error.message === 'Request failed with status code 500' || error.code === 500)
                ? defaultErrorMessage
                : error.message;
        }

        return defaultErrorMessage;
    }
}

export {
    ApiNonFatalError
}
