function setContext(context) {
    return {
        type: 'CONTEXT_SET',
        payload: {
            user: context ? {
                name: context.name,
                picture: context.picture,
                version: context.version,
                doqid: context.doqid,
                uuid: context.uuid,
                role: context.role,
                weblogin: context.weblogin,
                auth_time: context.auth_time,
                email: context.email,
                email_verified: context.email_verified
            } : null
        }
    }
}


export const ContextActions = {
    setContext,
}