const styles = theme => ({
	modal: {
		outline: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.7)'
	},
	container: {
		width: '100%',
		height: '100%',
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 15px',
	},
	fileContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 10,
		'& img': {
			height: '80vh',
			maxHeight: '80vh',
			maxWidth: '80vw'
		},
		'& video': {
			height: '80vh',
			maxHeight: '80vh',
			maxWidth: '80vw'
		}
	},
});


export default styles