import React, { useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import AuthRoute from 'routes/_/AuthRoute';
import AuthRedirect from 'routes/_/AuthRedirect';
import {
    LoginScreen,
    MainChat,
    MobileScreen
} from 'screens'
import firebase from 'firebase/app';
import "firebase/messaging";
import 'firebase/auth';
import JWT from 'services/jwt';
import { ContextActions, LoginActions, UIActions, NotificationActions } from 'redux/actions';
import { connect } from 'react-redux';
import { firebaseConfig } from 'config/firebase';
import { isMobile } from 'react-device-detect';

//UI
import { AnAlertBar, AnAlertBarManager } from 'components/an';
import theme from './theme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import {
    CircularProgress,
    Box
} from '@material-ui/core';

firebase.initializeApp(firebaseConfig)

if (firebase.messaging.isSupported()) {
    firebase.messaging().usePublicVapidKey(
        process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_CERTIFICATES
    );
}

const registerPushListener = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.addEventListener("message", ({ data }) => {
            if (data && data.firebaseMessaging) {
                if (data.firebaseMessaging.type === "push-received") {
                    //const { notification } = data.firebaseMessaging.payload;
                    //Waiting notification...
                } else {
                    // handle another events.
                }
            }
        });
    }
}

function App(props) {

    const [loading, setLoading] = React.useState(true)
    const {
        setContext,
        sessionListener,
        clearMessage,
        message,
        type,
        updateFCMToken,
        pushNotification
    } = props

    useEffect(() => {
        registerPushListener(pushNotification);
    }, [pushNotification]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const token = JWT.parseToken(await user.getIdToken())

                if (token.role === '3') {
                    setContext(token)
                    sessionListener()
                    setLoading(false)

                    //Check if the user granted notification permissions
                    if (Notification.permission === 'granted' &&
                        firebase.messaging.isSupported()) {
                        updateFCMToken()
                    }
                }
                else {
                    setContext(null)
                    setLoading(false)
                }
            } else {
                setContext(null)
                setLoading(false)
            }
        })
    }, [sessionListener, setContext, updateFCMToken]);

    const alertRef = useRef();

    useEffect(() => {
        if (message.length > 0) {
            AnAlertBarManager.showAlertBar(alertRef.current, {
                message: message,
                type: type,
            });
            clearMessage();
        }

    }, [message, clearMessage, type])

    //Check if the user uses the app from a mobile or tablet
    if (isMobile) {
        return (
            <MuiThemeProvider theme={theme} >
                <MobileScreen />
            </MuiThemeProvider>
        )
    } else {
        return (
            <MuiThemeProvider theme={theme} >
                <AnAlertBar
                    ref={alertRef} />

                {loading ?
                    <Box style={{
                        backgroundColor: '#f0f0f0'
                    }} display="flex" justifyContent="center" alignItems="center" height="100vh">
                        <CircularProgress
                            size={50}
                            color='primary'
                        />
                    </Box>
                    :
                    (<>
                        <div className='bkg-app'>
                        </div>
                        <Router>
                            <Switch>
                                <Route exact path="/login">
                                    <LoginScreen />
                                </Route>
                                <AuthRoute path='/' component={MainChat} />
                                <AuthRedirect from='/*' to='/' />
                            </Switch>
                        </Router>
                    </>)}
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    message: state.ui.message,
    type: state.ui.type,
});

const mapDispatchToProps = (dispatch) => ({
    setContext: (token) => dispatch(ContextActions.setContext(token)),
    sessionListener: () => dispatch(LoginActions.sessionListener()),
    clearMessage: () => dispatch(UIActions.clearMessage()),
    updateFCMToken: () => dispatch(NotificationActions.updateFCMToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

