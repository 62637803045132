// @flow

const defaultState = {
    users: {},
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'REFERENCES_FETCH_USER_DATA_START':
            return Object.assign({}, state, {
                loading: true,
            });

        case 'REFERENCES_FETCH_USER_DATA_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
                users: {
                    ...state.users,
                    [action.payload.doqid]: action.payload.user,
                },
            });

        case 'REFERENCES_FETCH_USER_DATA_FAILURE':
            return Object.assign({}, state, {
                loading: false,
            });

        case 'REFERENCES_RESET':
            return Object.assign({}, state, {
                ...defaultState,
            })

        default:
            return state;
    }
}
