import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Avatar,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import { LoginActions, ReferencesActions } from 'redux/actions';
import ModalNotifications from "components/LeftSide/NotificationsPermission";
import firebase from 'firebase/app';
import "firebase/messaging";

function LeftSideHeader(props) {

    const { classes,
        logoutWebSession,
        fetchUserData,
        context,
        professional } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        fetchUserData(context.user.doqid);
    }, [fetchUserData, context])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Card
                className={classes.root}
                color="secondary">
                <CardHeader
                    avatar={
                        <Avatar
                            aria-label="profesional"
                            src={professional ?
                                professional.ThumbnailId
                                : ''} />}
                    title={professional ?
                        `${professional.Nombre} ${professional.Apellido}`
                        : 'Cargando..'}
                    subheader={professional ?
                        `${professional.Titulo}. ${professional.Especialidad}`
                        : 'Cargando..'}
                    action={
                        <>
                            <IconButton
                                className={classes.actionBtn}
                                aria-label="settings"
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                keepMounted
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={() => {
                                    logoutWebSession();
                                }}>Cerrar Sesión</MenuItem>
                            </Menu>
                        </>
                    }
                />
            </Card>
            {Notification.permission === "default" &&
                firebase.messaging.isSupported() && (
                    <ModalNotifications />
                )}
        </React.Fragment>
    );
}

const mapStateToProps = (state, props) => {
    let professional = null;
    if (props.context) {
        professional = state.references.users[props.context.user.doqid]
    }
    return {
        professional
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchUserData: doqid => dispatch(
        ReferencesActions.fetchUserData(doqid),
    ),
    logoutWebSession: () => dispatch(
        LoginActions.logoutWebSession()
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideHeader);
