import React from 'react';
import { connect } from 'react-redux';
import LeftSide from 'components/LeftSide';
import {
    Container,
    Paper,
    Grid,
} from '@material-ui/core';
import ChatContainer from 'components/Chat/ChatContainer';
import FileViewer from 'components/FileViewer';

const MainChat = (props) => {
    const {
        classes,
        context } = props

    return (
        <div>
            <Container
                maxWidth="lg">
                <FileViewer />

                <Paper
                    elevation={12}
                    className={classes.root}>

                    <Grid
                        container >

                        <Grid
                            item xs={4}>

                            {<LeftSide />}

                        </Grid>

                        <Grid
                            item
                            xs={8}
                            container
                        >
                            <Grid
                                item
                                xs
                                container
                                direction="column"
                                alignItems="stretch"
                                style={{
                                    position: 'relative'
                                }}
                            >

                                <ChatContainer
                                    context={context} />

                            </Grid>

                        </Grid>

                    </Grid>

                </Paper>

            </Container>
        </div >
    );
}

const mapStateToProps = state => ({
    context: state.context,
})

const mapDispatchToProps = dispatch => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(MainChat)