import React from "react";
import {
	Modal,
	IconButton,
	Avatar,
	Typography
} from '@material-ui/core';
import { connect } from "react-redux";
import { FileViewerActions } from 'redux/actions';
import CloseIcon from '@material-ui/icons/Close';

class FileViewer extends React.PureComponent {

	handleClose = () => {
		this.props.close()
	}
	render() {
		const { classes } = this.props
		if (!this.props.fileviewer.open) {
			return null
		}
		return (
			<Modal
				open={this.props.fileviewer.open}
				onClose={this.handleClose}
				className={classes.modal}
				disableAutoFocus>
				<div
					className={classes.container}>

					<div className={classes.headerContainer}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center'
							}}>
							<Avatar
								src={this.props.fileviewer.thumbnailId}
							/>
							<div
								style={{
									height: '80%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: 10
								}}>
								<Typography
									style={{
										color: '#fff'
									}}>
									{this.props.fileviewer.me ?
										'Tú'
										:
										`${this.props.patient.Nombre} ${this.props.patient.Apellido}`}
								</Typography>
							</div>
						</div>

						<IconButton
							onClick={this.handleClose}>
							<CloseIcon
								fontSize="large"
								style={{
									color: '#fff'
								}} />
						</IconButton>

					</div>

					<div className={classes.fileContainer}>
						{this.props.fileviewer.type === 'image' &&
							(<img
								alt=''
								src={this.props.fileviewer.message.MetaData.url}
							/>)}
						{this.props.fileviewer.type === 'video' &&
							(<video
								controls
								autoPlay
								src={this.props.fileviewer.message.MetaData.url}
							/>)}
					</div>

				</div>
			</Modal >
		);
	}
}

const mapStateToProps = (state) => {
	let currentCase = null;
	let user = null;
	let patient = null;

	if (state.chat.openCases && state.chat.openCases[state.chat.activeChat]) {
		currentCase = state.chat.openCases[state.chat.activeChat];
	} else if (state.closedCases.closedCases && state.closedCases.closedCases[state.chat.activeChat]) {
		currentCase = state.closedCases.closedCases[state.chat.activeChat];
	}

	if (currentCase !== null) {
		user = state.references.users[currentCase.Participantes[0]];
		patient = state.references.users[currentCase.PacienteId];
	}

	return {
		fileviewer: state.fileviewer,
		user,
		patient
	}
};

const mapDispatchToProps = (dispatch) => ({
	close: () => dispatch(FileViewerActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileViewer);
