import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ChatActions, CloseCaseActions } from 'redux/actions';
import {
	Grid,
	Typography,
	Modal,
	Paper,
	Button,
	Avatar,
	CircularProgress,
} from '@material-ui/core';
import ChatHeader from 'components/Chat/components/ChatHeader';
import ChatMessageInput from 'components/RightSide/ChatMessageInput';
import RenderChatMessages from 'components/Chat/components/RenderChatMessages'
import bkg from 'assets/img/home-bkg.svg';
import doqchat from 'assets/img/iso.png';
import firebase from 'firebase/app';
import { CaseTypes } from 'constants/index';
import 'firebase/database';

class ChatContainer extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			chat: null,
			open: false
		}
		this.badgeRef = null;
		this.isActiveScreen = false;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.chat !== prevState.chat) {
			return {
				chat: nextProps.chat,
			};
		}
		else return null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.chat.activeChat !== this.state.chat.activeChat) {

			if (this.props.context.user.role === '3' && this.props.isOpenCase &&
				prevProps.chat.activeChat !== null) {
				this.props.stopRecordTime(this.props.context.user.doqid, prevProps.chat.activeChat.activeChat)
			}
			// Reset chat state
			this.props.reset()
		}
		if (this.props.chat && this.props.chat.activeChat &&
			this.props.chat.activeChat !== prevProps.chat.activeChat) {

			this.initializeChat();
		}
		if (prevProps.chat.initialFetchReady !== this.state.chat.initialFetchReady && this.state.chat.initialFetchReady) {
			// Start new messages listener when
			// first page is ready.
			return this.props.startNewMessagesListener();
		}

		if (prevProps.loadingClose && !this.props.loadingClose) {
			//Close modal when close case is finished
			this.handleCloseModal()
		}
	}

	componentDidActive(active) {
		if (active) {

			this.isActiveScreen = true;

			// Start chat timer
			if (this.props.chat.activeChat) {
				if (this.props.context.user.role === '3'
					&& !this.props.chat.initRecordTime
					&& this.props.isOpenCase) {
					this.props.recordTime();
				}
			}
		} else {
			this.isActiveScreen = false;
		}
	}

	async initializeChat() {

		// Notify chat seen
		this.props.notifyNewChatSeen(this.props.context.user.doqid);

		// Start live listeners
		if (this.props.currentCase) {
			this.props.startUserOnlineStatusListener(this.props.currentCase.Participantes[0]);
		}

		// Start chat state listener
		this.props.startChatStateListener();
		// Fetch inital page messages
		this.props.fetchInitialMessages();

		/* ------------------------------------------
			   Badge Listener
			   --------------------------------------------- */

		// We get node reference
		const badgeRef = firebase.database().ref(`Consultas/Chats/${this.props.chat.activeChat}/Estado/Badges/${this.props.context.user.doqid}`)

		// We attach the listener
		this.badgeRef = badgeRef.on('value', async (snapshot) => {
			if (snapshot.val() > 0) {
				try {
					// Set badge to 0 if chat is open
					await firebase.database().ref(`Consultas/Chats/${this.props.chat.activeChat}/Estado/Badges/${this.props.context.user.doqid}`).set(0);
				} catch (e) {
					// Nothing to do ...
				}

			}
		}, () => {
			// Nothing to do ...
		});


		/* ------------------------------------------
			Manage the initial badge
			--------------------------------------------- */

		try {
			// Set badge to 0 for this chat //
			await firebase.database().ref(`Consultas/Chats/${this.props.chat.activeChat}/Estado/Badges/${this.props.context.user.doqid}`).set(0);
		} catch (e) {
			// Nothing to do ...
		}

	}

	onSubmitEditing = (text) => {
		this.props.submitTextMessage(this.props.context.user.doqid, text);
	}


	onAudioRecorded = (audioName) => {
		//TODO: this.props.unsetRecordingAudio(this.props.context.user.doqid);

		if (audioName) {
			this.props.submitAudioMessage(this.props.context.user.doqid, audioName);
		}
	}

	handleCloseModal = () => {
		this.setState({
			open: !this.state.open
		})
	}

	render() {
		const { classes } = this.props
		if (this.props.currentCase && this.props.chat.activeChat) {
			return (
				<div
					className={classes.chatContainer}>
					<div
						style={{
							gridArea: '1 / 1 / 2 / 6',
							borderBottomRightRadius: 4,
						}}>
						<ChatHeader
							isOpenCase={this.props.isOpenCase}
							context={this.props.context}
							showLastConnectionStatus={false}
							handleCloseModal={this.handleCloseModal}
							fetchInvestedTime={this.props.fetchInvestedTime} />
					</div>
					<div
						style={{
							gridArea: '2 / 1 / 3 / 2',
							position: 'relative',
							display: 'flex',
							flexDirection: 'column-reverse',
						}}>
						{this.props.currentCase &&
							this.props.currentCase.EscribirMensajes
							&& !this.props.currentCase.SinCredito
							&& this.props.currentCase.FormatoApertura === 1 && (
								<ChatMessageInput
									context={this.props.context}
									onSubmitEditing={this.onSubmitEditing}
									onAudioRecorded={this.onAudioRecorded}
									submitImageMessage={this.props.submitImageMessage}
									submitFileMessage={this.props.submitFileMessage}
									submitVideoMessage={this.props.submitVideoMessage}
								/>)
						}

						{this.props.currentCase.EstadoConsulta === 30
							|| ((this.props.currentCase.EstadoConsulta === 25
								|| this.props.currentCase.EstadoConsulta === 21)
								&& this.props.context.user.role === '3')
							? (<div
								style={{
									position: "relative",
									display: "flex",
									flexDirection: 'column',
									alignItems: 'center',
									bottom: 0,
									backgroundColor: '#f0f0f0',
									padding: 8
								}}>
								<Typography
									color='primary'
									variant='h6'>
									CONSULTA FINALIZADA
								</Typography>
								<Typography
									variant='subtitle1'
									style={{
										fontSize: 12
									}}>
									La consulta se encuentra en modo de sólo lectura
								</Typography>

							</div>) : null}

						<RenderChatMessages
							context={this.props.context}
							chat={this.props.chat}
							activeChat={this.props.chat.activeChat}
							currentCase={this.props.currentCase}
							professional={this.props.professional}
							patient={this.props.patient}
							user={this.props.user}
							fetchMoreMessages={this.props.fetchMoreMessages}
							otherDevice={this.props.otherDevice}
						/>

						<Modal
							open={this.state.open}
							onClose={this.handleCloseModal}
							style={{
								outline: 0
							}}
						>
							<Paper
								elevation={12}
								className={classes.modalPaperContainer}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										marginBottom: 20
									}}>
									<Typography>
										{(this.props.currentCase.TipoConsulta === CaseTypes.CONSULTATION) ? 'Consulta' : ''}
										{(this.props.currentCase.TipoConsulta === CaseTypes.MESSAGE) ? 'Mensaje' : ''}
										{(this.props.currentCase.TipoConsulta === CaseTypes.RECIPE) ? 'Receta' : ''}
									</Typography>

									{this.props.bonus ? (
										<Typography
										>
											Bonificada
										</Typography>
									) : (
											<div>
												<Typography
													style={{
														color: '#E95855'
													}}>
													{(!this.props.currentCase.Valor || this.props.currentCase.Valor === 0) ? 'Sin cargo' : `$${this.props.currentCase.Valor}`}
												</Typography>
												{(this.props.currentCase.PagoVoluntario) &&
													(<Typography>
														Pago voluntario
													</Typography>)}
											</div>
										)}
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}>

									<Avatar
										src={this.props.patient ? this.props.patient.ThumbnailId : ''}
									/>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'flex-start',
											marginLeft: 10,
										}}>
										<Typography
											variant='caption'>
											Paciente
									</Typography>

										<Typography
											variant='subtitle1'>
											{this.props.patient ? `${this.props.patient.Nombre} ${this.props.patient.Apellido}` : 'Cargando..'}
										</Typography>

									</div>

								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center'
									}}>
									<Typography
										variant="subtitle1"
										align='center'
										style={{
											fontSize: 14,
											fontWeight: '600'
										}}>
										¿Está seguro que desea terminar la consulta?
									</Typography>
									<Typography
										variant="subtitle1"
										align='center'
										style={{
											fontSize: 12,
											fontWeight: 'regular'
										}}>
										No podrá seguir escribiendo mensajes y la consulta pasará a un estado de sólo lectura.
								</Typography>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											this.props.closeCase()
										}}>

										{this.props.loadingClose ?
											<CircularProgress
												color='secondary'
												size={25} />
											:
											'TERMINAR CONSULTA'}
									</Button>
								</div>
							</Paper>
						</Modal>

					</div>
				</div >
			);
		} else {
			return (
				<>
					<Grid
						item
						className={classes.landingContainer}  >
						<img
							style={{
								width: 600
							}}
							src={bkg}
							alt='DoqChat logo icon png' />
						<div className={classes.appTitle}>
							<img
								src={doqchat}
								alt='DoqChat logo icon png' />
							<h3>DOQCHAT WEB</h3>
						</div>
						<div
							className={classes.topAlert}>
							<Typography
								variant='caption'
								component='h4'>
								Para tu comodidad no es necesario que mantengas tu telefono conectado
								</Typography>
						</div>
					</Grid>
				</>)
		}
	}
}

const mapStateToProps = (state, props) => {

	let isOpenCase = null;
	let currentCase = null;
	let otherDevice = null;
	let professional = null;
	let user = null;
	let patient = null;


	if (state.chat.openCases && state.chat.openCases[state.chat.activeChat]) {
		currentCase = state.chat.openCases[state.chat.activeChat];
		isOpenCase = state.chat.openCases[state.chat.activeChat] ? true : false;
	} else if (state.closedCases.closedCases && state.closedCases.closedCases[state.chat.activeChat]) {
		currentCase = state.closedCases.closedCases[state.chat.activeChat];
		isOpenCase = state.closedCases.closedCases[state.chat.activeChat] ? false : true;
	}

	if (currentCase !== null) {
		otherDevice = currentCase.Participantes[0];
		professional = state.references.users[props.context.user.doqid];
		user = state.references.users[currentCase.Participantes[0]];
		patient = state.references.users[currentCase.PacienteId];
	}

	return {
		chat: state.chat,
		isOpenCase,
		currentCase,
		professional,
		patient,
		user,
		otherDevice,
		bonus: state.closeCase.bonus,
		investedTime: state.closeCase.investedTime,
		loadingClose: state.closeCase.loading
	}
}

const mapDispatchToProps = dispatch => ({
	startChatStateListener: () => dispatch(ChatActions.startChatStateListener()),
	startUserOnlineStatusListener: (doqid) => dispatch(ChatActions.startUserOnlineStatusListener(doqid)),
	fetchInitialMessages: () => dispatch(ChatActions.fetchInitialMessages()),
	startNewMessagesListener: () => dispatch(ChatActions.startNewMessagesListener()),
	fetchMoreMessages: () => dispatch(ChatActions.fetchMoreMessages()),
	submitTextMessage: (doqid, text) => dispatch(ChatActions.submitTextMessage(doqid, text)),
	submitAudioMessage: (doqid, audioName) => dispatch(ChatActions.submitAudioMessage(doqid, audioName)),
	notifyNewChatSeen: doqid => dispatch(ChatActions.notifyNewChatSeen(doqid)),
	recordTime: () => dispatch(ChatActions.recordTime()),
	stopRecordTime: (doqid, activeChat) => dispatch(ChatActions.stopRecordTime(doqid, activeChat)),
	reset: () => dispatch(ChatActions.reset()),
	fetchInvestedTime: doqid => dispatch(CloseCaseActions.fetchInvestedTime(doqid)),
	closeCase: () => dispatch(CloseCaseActions.closeCase()),
	submitImageMessage: (doqid, file) => dispatch(ChatActions.submitImageMessage(doqid, file)),
	submitFileMessage: (doqid, file) => dispatch(ChatActions.submitFileMessage(doqid, file)),
	submitVideoMessage: (doqid, file) => dispatch(ChatActions.submitVideoMessage(doqid, file)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer)