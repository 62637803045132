import React from 'react'
import logo from 'assets/img/logo.svg';
import banner from 'assets/img/banner.png';
import { Typography } from '@material-ui/core';


export default class MobileScreen extends React.PureComponent {

	render() {
		const { classes } = this.props
		return (
			<div
				className={classes.container}>
				<div
					className={classes.appTitle}>
					<img
						src={logo}
						alt='DoqChat logo icon png' />
					<h3>DOQCHAT WEB</h3>
				</div>
				<div
					className={classes.contentContainer}>
					<Typography
						variant="h3"
						style={{
							color: '#fff',
							textAlign: 'center',
							padding: 10
						}}
					>
						Una nueva forma de estar comunicado con tus pacientes
				</Typography>

					<img
						src={banner}
						alt='DoqChat chat celular doctor pacient'
						variant="h3"
						style={{
							width: 300
						}} />
				</div>
			</div>)
	}
}