import React, { PureComponent } from 'react';

class ChatMessageHOC extends PureComponent {
	render() {
		if (this.props.type === 'info') {
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						margin: 8,
						maxWidth: '60%',
						minWidth: '60%',
						alignSelf: 'center',
						borderRadius: 5,
						boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.2)'
					}}
				>
					{this.props.children}
				</div>
			);
		}

		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: (this.props.me) ? 'row-reverse' : 'row',
					alignSelf: (this.props.me) ? 'flex-end' : 'flex-start',
					margin: 5,
				}}
			>
				<div
					style={{
						display: 'flex',
						zIndex: 0,
						borderRadius: 5,
						paddingBottom: 8,
						paddingTop: 8,
						flexDirection: 'row-reverse',
						maxWidth: (this.props.type) !== 'image' ? 400 : 'none',
						backgroundColor: (this.props.me) ? '#daf0fe' : '#fff',
						paddingLeft: (this.props.me) ? this.props.type !== 'image' ? 10 : 5 : this.props.type !== 'image' ? 10 : 5,
						paddingRight: this.props.type !== 'image' ? 20 : 5,
						position: 'relative',
						boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.2)'
					}}
				>
					{this.props.children}
				</div>
			</div >
		);
	}
}

export default ChatMessageHOC;
