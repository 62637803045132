const defaultState = {
    message: '',
    type: 'error'
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case 'UI_ALERT_SET_SUCCESS':
            return Object.assign({}, state, {
                type: 'success',
                message: action.payload.message
            });
        case 'UI_ALERT_SET_ERROR':
            return Object.assign({}, state, {
                type: 'error',
                message: action.payload.error
            });

        case 'UI_ALERT_CLEAR_MESSAGE':
            return Object.assign({}, state, {
                message: ''
            });

        default:
            return state;
    }
}
