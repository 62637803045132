const styles = theme => ({
	buttonsContainer: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		right: 0,
		top: 0,
		height: '100%'
	}
});

export default styles