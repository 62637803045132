import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import logo from 'assets/img/logo.svg';
import {
    Container,
    Paper,
    Grid,
    Typography,
    CircularProgress,
    Box
} from '@material-ui/core';
import { LoginActions } from 'redux/actions';
import ReplayIcon from '@material-ui/icons/Replay';
import LanguageIcon from '@material-ui/icons/Language';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Store from 'redux/index';

function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}


const LoginScreen = (props) => {
    const { classes,
        getQRWebLogin,
        qrCode,
        loading,
        history,
        user,
        expiredDate,
        loginState } = props;
    const [loadingImage, setLoadingImage] = useState(true)
    const [expired, setExpired] = useState(false);
    const [nowDate, setNowDate] = useState(new Date())
    const [delay, setDelay] = useState(1000)

    useEffect(() => {
        if (user) {
            history.push('/')
        } else {
            getQRWebLogin()
        }
    }, [user, getQRWebLogin, history]);


    useInterval(() => {
        if (!!expiredDate && nowDate >= new Date(expiredDate)) {
            setExpired(true)
            setDelay(null)
        }
        else {
            setExpired(false)
        }
        setNowDate(new Date())
    }, delay);


    const imgLoaderHandler = (i) => {
        if (i) {
            setLoadingImage(false)
            Store.dispatch({
                type: 'LOGIN_STATE_SECOND_STEP_UPDATE'
            })
        }
    }

    const resetQRToken = () => {
        setDelay(1000)
        setExpired(false)
        getQRWebLogin()
    }

    return (
        <div>
            <Container maxWidth="lg">
                <div className={classes.appTitle}>
                    <img src={logo} alt='DoqChat logo icon png' />
                    <h3>DOQCHAT WEB</h3>
                </div>

                <div className={classes.root}>
                    <Paper className={classes.paper} elevation={12}>
                        <Grid container spacing={2} justify='space-around' >
                            <Grid item sm container direction="column" >
                                <Grid item xs>
                                    <Typography gutterBottom variant="h3" style={{
                                        paddingTop: 30,
                                        paddingLeft: 30,
                                        paddingBottom: 20
                                    }}>
                                        Para usar DoqChat en tu computadora:</Typography>
                                    <ol className={classes.stepOl}>
                                        <li>
                                            <Typography variant="h5" gutterBottom
                                            >
                                                Abre DoqChat en tu teléfono</Typography>
                                        </li>
                                        <li >
                                            <Typography variant="h5" gutterBottom
                                            >
                                                Toca el botón <span><LanguageIcon style={{ verticalAlign: 'middle' }} /></span> en la pantalla principal
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography
                                                variant='h5' gutterBottom
                                            >Escanea el código QR de esta pantalla</Typography>
                                        </li>
                                    </ol>
                                </Grid>
                            </Grid>
                            <Grid item >
                                {loading &&
                                    (<Box className={classes.imgContainer}>
                                        <CircularProgress size={40} />
                                    </Box>)}

                                {(!loading) &&
                                    (<Box className={classes.imgContainer}>
                                        {(expired) &&
                                            (<Box className={classes.expiredBox}>
                                                <button onClick={resetQRToken} >
                                                    <ReplayIcon fontSize='large' />
                                                    <h6>HAZ CLICK PARA ACTUALIZAR EL CÓDIGO QR</h6>

                                                </button>
                                            </Box>)}
                                        {loadingImage &&
                                            (<Box style={{
                                                position: 'absolute'
                                            }}>
                                                <CircularProgress size={40} />
                                            </Box>)}
                                        <img
                                            onLoad={(i) => imgLoaderHandler(i)}
                                            className={classes.qrCode}
                                            style={{
                                                opacity: expired ? 0.3 : 1,
                                            }}
                                            src={`${process.env.REACT_APP_BASE_API_URL}/usuarios/crearqrweblogin?qrCode=${qrCode}`}
                                            alt='' />
                                    </Box>)}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    {Object.keys(loginState).map((state, index) =>
                                        (<div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                padding: 5
                                            }}>
                                            {loginState[state].verify ?
                                                <CheckBoxIcon />
                                                :
                                                <CheckBoxOutlineBlankIcon />
                                            }
                                            <Typography>{loginState[state].label}</Typography>
                                        </div>
                                        ))
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>

            </Container>
        </div >
    );
}

const mapStateToProps = state => ({
    loading: state.login.loading,
    qrCode: state.login.qrCode,
    user: state.context.user,
    expiredDate: state.login.expiredDate,
    loginState: state.login.loginState,
})

const mapDispatchToProps = dispatch => ({
    getQRWebLogin: () => dispatch(LoginActions.getQRWebLogin()),
    reset: () => dispatch(LoginActions.reset()),
})


export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)