const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  fetchMoreCasesContainer: {
    position: 'absolute',
    bottom: 8,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fetchMoreCasesFlag: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 40,
    padding: 6,
  }
});

export default styles