import RestClient from 'http/RestClient'

export default class UserRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(undefined,
            useCredentials,
            refreshContext)
    }

    /* 
    * Get QR web login
    */
    getQRWebLogin() {
        const url = `${this.baseURL}/usuarios/obtenerqrweblogin`
        return this.instance.get(url)
    }

    /* 
    * Post code for step 2
    */
    loginWebWithQRStepTwo(params) {
        const url = `${this.baseURL}/usuarios/loginwebporqrstep2`

        return this.instance.post(url, {
            tokenFCM: params.tokenFCM,
            codigo: params.code,
            versionBrowser: params.browserName,
            versionSistemaOperativo: params.operatingSystem
        });
    }

    /* 
    * Logout session web
    */
    logoutWebSession() {
        const url = `${this.baseURL}/usuarios/logoutweb`

        return this.instance.post(url, {});
    }
    /*
     * Update FCM Token
     */
    updateFCMToken(params) {
        const url = `${this.baseURL}/usuarios/actualizarnotificaciones`

        return this.instance.post(url, {
            tokenFCM: params.token,
        });
    }

}