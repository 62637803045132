import firebase from 'firebase/app';
import 'firebase/auth';
import FirebaseListenersRegistry from 'services/FirebaseListenersRegistry';
import _ from 'lodash';
import moment from 'moment';
/* ---------------------
    Private Methods.
    ---------------- */

/**
 *  Get results ordered.
 *
 *  @param {Object} results - The resultset.
 */
function orderBy(results) {
    const arr = Object.entries(results)
        .map(item => ({ ...item[1], key: item[0] }));

    const sorted = _.orderBy(arr.filter((item) => item.Data && item.Data.datetime),
        [message => message.Data.datetime], ['desc'],
    )

    return {
        results: sorted,
        last: (sorted.length > 0) ? sorted[sorted.length - 1].Data.datetime : 0,
        first: (sorted.length > 0) ? sorted[0].Data.datetime : 0,
    };
}

/**
 *  @function setActiveChat
 *
 *  Set current chat as active.
 *
 *  @param {number} activeChat - The chat id.
 */
function setActiveChat(activeChat) {
    return (dispatch) => {
        dispatch({
            type: 'ACTIVE_CHAT_SET',
            payload: {
                activeChat,
            },
        });
    };
}

/**
 * Fetch open cases.
 * @param {string} doqid The doqid.
 */
function fetchOpenCases(doqid) {
    return async dispatch => {
        dispatch({
            type: 'PROFESSIONAL_FETCH_OPEN_CASES_LOADING'
        })

        const ref = firebase.database().ref(`ViewModels/Data/${doqid}/ConsultasAbiertas/ListaConsultasAbiertas`)

        ref.on('value', (snapshot) => {
            dispatch({
                type: 'PROFESSIONAL_FETCH_OPEN_CASES_SUCCESS',
                payload: {
                    openCases: snapshot.val(),
                },
            });

        }, (error) => {
            dispatch({
                type: 'PROFESSIONAL_FETCH_OPEN_CASES_FAILURE',
                payload: {
                    error: error,   // ErrorManager.toMessage(error),
                },
            });
        });
    }
}


/**
 *  @function startChatStateListener
 *
 *  Fetch the user online status.
 */
function startChatStateListener() {
    return async (dispatch, getState) => {
        const { activeChat } = getState().chat;

        dispatch({
            type: 'CHAT_FETCH_CHAT_STATE_START',
        });

        const ref = firebase.database().ref(`Consultas/Chats/${activeChat}/Estado/Participantes`)

        ref.on('value', (snapshot) => {
            dispatch({
                type: 'CHAT_FETCH_CHAT_STATE_SUCCESS',
                payload: {
                    chatState: snapshot.val(),
                },
            });
        }, (error) => {
            //TODO: verificar este manejo de errores
            dispatch({
                type: 'CHAT_FETCH_CHAT_STATE_FAILURE',
            });
        });

        FirebaseListenersRegistry.add(`Consultas/Chats/${activeChat}/Estado/Participantes`)
    };
}

/**
 *  @function startUserOnlineStatusListener
 *
 *  Fetch the user online status.
 *
 *  @param {number} doqid - The user doqid.
 */
function startUserOnlineStatusListener(doqid) {
    return async (dispatch) => {
        dispatch({
            type: 'CHAT_FETCH_USER_ONLINE_STATUS_START',
        });

        const ref = firebase.database().ref(`OnlineStatus/${doqid}`)

        ref.on('value', (snapshot) => {
            dispatch({
                type: 'CHAT_FETCH_USER_ONLINE_STATUS_SUCCESS',
                payload: {
                    userOnlineStatus: snapshot.val(),
                },
            });
        }, () => {
            dispatch({
                type: 'CHAT_FETCH_USER_ONLINE_STATUS_FAILURE',
            });
        });

        FirebaseListenersRegistry.add(`OnlineStatus/${doqid}`)
    };
}

/**
 *  @function fetchInitialMessages
 *
 *  Fetch first page messages
 */
function fetchInitialMessages() {
    return async (dispatch, getState) => {
        const { activeChat, pageSize } = getState().chat;

        dispatch({
            type: 'CHAT_FETCH_INITIAL_MESSAGES_START',
        });

        const ref = firebase.database().ref(`Consultas/Chats/${activeChat}/Mensajes`);

        ref.orderByChild('Data/datetime')
            .limitToLast(pageSize)
            .once('value', (snapshot) => {
                if (snapshot.exists()) {
                    const { results, last, first } = orderBy(snapshot.val());
                    dispatch({
                        type: 'CHAT_FETCH_INITIAL_MESSAGES_SUCCESS',
                        payload: {
                            messages: results,
                            initialIndex: first,
                            lastIndex: last,
                        },
                    });
                } else {
                    dispatch({
                        type: 'CHAT_FETCH_INITIAL_MESSAGES_SUCCESS',
                        payload: {
                            messages: [],
                            initialIndex: 0,
                        },
                    });
                }
            }, (error) => {
                // TODO: Manejar errores, en la app no hay manejo de estos
            });
    };
}

/**
 *  @function startNewMessagesListener.
 */
function startNewMessagesListener() {
    return async (dispatch, getState) => {
        const { activeChat,
            initialIndex } = getState().chat;

        firebase.database().ref(`Consultas/Chats/${activeChat}/Mensajes`)
            .orderByChild('Data/datetime')
            .startAt(initialIndex + 1)
            .on('child_added', (snapshot) => {
                if (snapshot.val() !== null) {
                    const message = snapshot.val();

                    // Add key to message
                    message.key = snapshot.key;

                    dispatch({
                        type: 'CHAT_MESSAGE_ADD',
                        payload: {
                            message,
                        },
                    });
                }
            }, (error) => {
                if (error) {
                    //TODO: manejar errores
                }
            });

        FirebaseListenersRegistry.add(`Consultas/Chats/${activeChat}/Mensajes`)

        firebase.database().ref(`Consultas/Chats/${activeChat}/Mensajes`)
            .orderByChild('Data/datetime')
            .on('child_changed', (snapshot) => {

                if (snapshot.val() !== null) {
                    const message = snapshot.val();

                    // Add key to message
                    message.key = snapshot.key;

                    dispatch({
                        type: 'CHAT_MESSAGE_CHANGE',
                        payload: {
                            message,
                        },
                    });
                }
            }, (error) => {
                // TODO: manejar error
            });

        FirebaseListenersRegistry.add(`Consultas/Chats/${activeChat}/Mensajes`)
    };
}


/**
 *  Fetch more messages.
 */
function fetchMoreMessages() {
    return async (dispatch, getState) => {
        const { chat } = getState();

        if (isNaN(chat.lastIndex - 1)) return;
        if (chat.loadingMessages) return;

        if (!chat.fetchingMoreMessages) {
            dispatch({
                type: 'CHAT_FETCH_MORE_MESSAGES_START',
            });

            firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Mensajes`)
                .orderByChild('Data/datetime')
                .endAt(chat.lastIndex - 1)
                .limitToLast(chat.pageSize)
                .on('value', (snapshot) => {
                    if (snapshot && snapshot.val() !== null) {
                        const { results, last } = orderBy(snapshot.val());

                        setTimeout(() => {
                            dispatch({
                                type: 'CHAT_FETCH_MORE_MESSAGES_SUCCESS',
                                payload: {
                                    messages: results,
                                    lastIndex: last,
                                },
                            });
                        }, 1000);
                    } else {
                        dispatch({
                            type: 'CHAT_FETCH_MORE_MESSAGES_SUCCESS',
                            payload: {
                                messages: [],
                            },
                        });
                    }
                }, (error) => {
                    dispatch({
                        type: 'CHAT_FETCH_MORE_MESSAGES_FAILURE',
                    });
                });
        }
    };
}

/**
 *  @function submitTextMessage
 *
 *  Submit a text message.
 *
 *  @param {number} doqid - The user doqid.
 *  @param {string} text - The message text.
 */
function submitTextMessage(doqid, text) {
    return async (dispatch, getState) => {
        const { chat } = getState();

        const ref = firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Mensajes`);

        ref.push({
            Data: {
                type: 'message',
                datetime: firebase.database.ServerValue.TIMESTAMP,
                mensaje: text.trim(),
                sender: doqid,
            },
            Estado: {
                leido: false,
                recibido: false,
                enviado: false,
                uploaded: true,
            },
        }, () => {
            // Nothing to do ..
        });
    };
}

/**
 *  @function submitAudioMessage
 *
 *  Submit a audio message.
 *
 *  @param {number} doqid - The user doqid.
 *  @param {string} audioName - The audio message name.
 */
function submitAudioMessage(doqid, audioName) {
    return async (dispatch, getState) => {
        const { chat } = getState();

        const ref = firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Mensajes`);
        ref.push({
            Data: {
                type: 'ptt',
                datetime: firebase.database.ServerValue.TIMESTAMP,
                mensaje: audioName,
                sender: doqid,
            },
            Estado: {
                leido: false,
                recibido: false,
                enviado: false,
                uploaded: false,
            },
        });
    };
}

/**
 *  @function notifyNewChatSeen
 *
 * Notify the api that the professional
 * has seen he has a new case.
 *
 */
function notifyNewChatSeen(doqid) {
    return async (dispatch, getState) => {
        const {
            chat
        } = getState();

        const ref1 = firebase.database().ref(`/Consultas/Chats/${chat.activeChat}/Estado/Datos/${doqid}/VistoFechaHora`);

        ref1.transaction((value) => {
            if (value === null || value === 0) {
                return firebase.database.ServerValue.TIMESTAMP;
            }
        }, () => {
            // Nothing to do ...
        });

        const ref2 = firebase.database().ref(`/Consultas/Chats/${chat.activeChat}/Estado/Datos/${doqid}/Visto`);

        ref2.transaction((value) => {
            if (value === null || value === false) {
                return true;
            }
        }, () => {
            // Nothing to do ...
        });
    };
}

/**
 *  Record time start.
 */
function recordTime() {
    return async (dispatch, getState) => {
        dispatch({
            type: 'CHAT_INIT_RECORD_TIME',
            payload: {
                initRecordTime: moment(),
            },
        });
    };
}


/**
 *  Record time start.
 *
 *  @param {number} doqid - The user doqid.
 */
function stopRecordTime(doqid, activeChat) {
    return async (dispatch, getState) => {
        const {
            chat
        } = getState();

        const ref = firebase.database().ref(`Consultas/Chats/${activeChat}/Estado/Datos/${doqid}/SegundosEnChat`)

        ref.once('value', snapshot => {
            if (snapshot && _.isNumber(snapshot.val())) {
                firebase.database().ref().update({
                    [`Consultas/Chats/${activeChat}/Estado/Datos/${doqid}/SegundosEnChat`]: snapshot.val() + (moment().diff(chat.initRecordTime, 'seconds'))
                })
            }
        })
    };
}


/**
 *  Submit an image message.
 *
 *  @param {number} doqid - The user doqid.
 *  @param {string} uri - The local image uri.
 */
function submitImageMessage(doqid, file) {
    return async (dispatch, getState) => {
        const { chat } = getState();

        const ref = firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Mensajes`);

        ref.push({
            Data: {
                type: 'image',
                datetime: firebase.database.ServerValue.TIMESTAMP,
                sender: doqid,
                mensaje: file.path,
                mimeType: file.mimeType,
                extension: file.extension,
                filename: file.name,
            },
            Estado: {
                leido: false,
                recibido: false,
                enviado: false,
                uploaded: false,
            },
        });
    };
}

/**
 *  Submit an image message.
 *
 *  @param {number} doqid - The user doqid.
 *  @param {string} uri - The local file uri.
 */

function submitFileMessage(doqid, file) {
    return async (dispatch, getState) => {
        const { chat } = getState();

        firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Mensajes`)
            .push({
                Data: {
                    sender: doqid,
                    datetime: firebase.database.ServerValue.TIMESTAMP,
                    type: 'file',
                    mensaje: file.path,
                    mimeType: file.mimeType,
                    extension: file.extension,
                    filename: file.name,
                },
                Estado: {
                    leido: false,
                    recibido: false,
                    enviado: false,
                    uploaded: false,
                },
            });
    }

}
/**
 *  Submit an video message.
 *
 *  @param {number} doqid - The user doqid.
 */
function submitVideoMessage(doqid, file) {
    return async (dispatch, getState) => {
        const { chat } = getState();

        firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Mensajes`)
            .push({
                Data: {
                    type: 'video',
                    datetime: firebase.database.ServerValue.TIMESTAMP,
                    sender: doqid,
                    mensaje: file.path,
                    mimeType: file.mimeType,
                    extension: file.extension,
                    filename: file.name,
                },
                Estado: {
                    leido: false,
                    recibido: false,
                    enviado: false,
                    uploaded: false,
                },
            });
    };
}


/**
 *  @function chatReset
 *
 *  Reset chat to initial state.
 */
function reset() {
    return {
        type: 'CHAT_RESET',
    };
}



export const ChatActions = {
    fetchOpenCases,
    setActiveChat,
    startChatStateListener,
    startUserOnlineStatusListener,
    fetchInitialMessages,
    startNewMessagesListener,
    fetchMoreMessages,
    submitTextMessage,
    submitAudioMessage,
    notifyNewChatSeen,
    recordTime,
    stopRecordTime,
    submitImageMessage,
    submitFileMessage,
    submitVideoMessage,
    reset
}