// @flow

const defaultState: any = {
	loading: false,
	ready: false,
	error: null,
	bonus: false,
	investedTime: null,
	notCorresponds: false,
};

/**
 *  The redux reducer for auth.
 *
 *  @param {any} state - The current state.
 *  @param {any} action - The current action.
 */
export default function reducer(state: any = defaultState, action: any) {
	switch (action.type) {
		case 'PROFESSIONAL_CLOSE_CASE_CLOSE_CASE_START':
			return Object.assign({}, state, {
				loading: true,
				ready: false,
			});

		case 'PROFESSIONAL_CLOSE_CASE_CLOSE_CASE_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				ready: true,
			});

		case 'PROFESSIONAL_CLOSE_CASE_CLOSE_CASE_FAILURE':
			return Object.assign({}, state, {
				loading: false,
			});

		case 'PROFESSIONAL_CLOSE_CASE_INVESTED_TIME_CALCULATE_START':
			return Object.assign({}, state, {
				investedTime: null,
			})

		case 'PROFESSIONAL_CLOSE_CASE_INVESTED_TIME_SET':
			return Object.assign({}, state, {
				investedTime: action.payload.investedTime,
			});

		case 'PROFESSIONAL_CLOSE_CASE_BONUS_SET':
			return Object.assign({}, state, {
				bonus: action.payload.bonus,
			});

		case 'PROFESSIONAL_CLOSE_CASE_NOT_CORRESPONDS_SET':
			return Object.assign({}, state, {
				notCorresponds: action.payload.notCorresponds,
			});

		case 'PROFESSIONAL_CLOSE_CASE_CLEAR_ERROR':
			return Object.assign({}, state, {
				error: null,
			});

		case 'PROFESSIONAL_CLOSE_CASE_RESET_STATE':
			return Object.assign({}, state, {
				loading: false,
				error: null,
				bonus: false,
				investedTime: null,
				notCorresponds: false,
			});

		default:
			return state;
	}
}
