// @flow

const defaultState: any = {
	loadingCases: true,
	closedCases: null,
	paginatedClosedCases: [],
	error: null,
	lastIndex: null,
	pageSize: 20,
	totals: null,
	moreClosedCasesFlag: true,
	fetchingMoreClosedCases: false,
};

/**
 *  The redux reducer for auth.
 *
 *  @param {any} state - The current state.
 *  @param {any} action - The current action.
 */
export default function reducer(state: any = defaultState, action: any) {
	switch (action.type) {
		case 'PROFESSIONAL_CLOSED_CASES_TOTALS':
			return Object.assign({}, state, {
				totals: action.payload.totals
			})

		case "PROFESSIONAL_FETCH_CLOSED_CASES_LOADING":
			return Object.assign({}, state, {
				loadingCases: true,
			});

		case 'PROFESSIONAL_FETCH_CLOSED_CASES_SUCCESS':
			return Object.assign({}, state, {
				loadingCases: false,
				paginatedClosedCases: action.payload.closedCases || [],
				lastIndex: action.payload.lastIndex,
				moreClosedCasesFlag: action.payload.closedCases.length >= defaultState.pageSize,
			});


		case "PROFESSIONAL_FETCH_CLOSED_CASES_FAILURE":
			return Object.assign({}, state, {
				loadingCases: false,
			});


		case 'PROFESSIONAL_FETCH_MORE_CLOSED_CASES_START':
			return Object.assign({}, state, {
				fetchingMoreClosedCases: true,
			});

		case 'PROFESSIONAL_FETCH_MORE_CLOSED_CASES_SUCCESS':
			return Object.assign({}, state, {
				paginatedClosedCases: state.paginatedClosedCases.concat(action.payload.closedCases),
				lastIndex: action.payload.lastIndex || state.lastIndex,
				moreClosedCasesFlag: action.payload.closedCases.length >= defaultState.pageSize,
				fetchingMoreClosedCases: false,
			});

		case 'PROFESSIONAL_FETCH_MORE_CLOSED_CASES_FAILURE':
			return Object.assign({}, state, {
				fetchingMoreClosedCases: false,
			});

		case 'PROFESSIONAL_CLOSED_CASES_LISTENER_SUCCESS':
			return Object.assign({}, state, {
				closedCases: action.payload.closedCases,
			})
		default:
			return state;
	}
}
