const styles = theme => ({
  root: {
    cursor: 'pointer',
    display: 'grid',
    maxWidth: '100%',
    gridTemplateColumns: '1fr 4fr 2fr',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridColumnGap: 0,
    padding: '16px 5px'
  },
  avatarContainer: {
    gridArea: '1 / 1 / 3 / 2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    gridArea: ' 1 / 2 / 3 / 3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 5,
  },
  hourContainer: {
    gridArea: '1 / 3 / 2 / 4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  badgeContainer: {
    gridArea: '2 / 3 / 3 / 4',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    // height: 100,
    // width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
});

export default styles