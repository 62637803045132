import React, { PureComponent } from 'react';

import {
	Card,
	CardHeader,
	Avatar,
	IconButton
} from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';
import { CaseTypes } from 'constants/index';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

class ChatHeader extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			subtitle: '',
			currentCase: null,
			chat: null,
		};
		this.isPatient = false // TODO: verify

	}
	componentDidMount() {
		this.onSubtitleChange(this.props);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.chat !== prevState.chat ||
			nextProps.currentCase !== prevState.currentCase) {
			return {
				chat: nextProps.chat,
				currentCase: nextProps.currentCase
			};
		}
		else return null;
	}

	componentDidUpdate(prevProps) {
		this.onSubtitleChange(prevProps);
	}

	onSubtitleChange(prevState) {
		let text;

		if (!this.state.currentCase) return;

		if (this.state.currentCase.EstadoConsulta !== 15) {
			text = (this.state.currentCase.TipoConsulta === CaseTypes.CONSULTATION)
				? 'Consulta'
				: (this.state.currentCase.TipoConsulta === CaseTypes.MESSAGE)
					? 'Mensaje'
					: (this.state.currentCase.TipoConsulta === CaseTypes.RECIPE)
						? 'Receta'
						: 'Desconocido';
		} else if (this.state.chat && this.state.chat.chatState && this.state.currentCase && this.state.currentCase.Participantes && this.state.chat.chatState[this.state.currentCase.Participantes[0]] && this.state.chat.chatState[this.state.currentCase.Participantes[0]].Escribiendo) {

			text = 'escribiendo...'

		} else if (this.state.chat && this.state.chat.chatState && this.state.currentCase && this.state.currentCase.Participantes && this.state.chat.chatState[this.state.currentCase.Participantes[0]] && this.state.chat.chatState[this.state.currentCase.Participantes[0]].GrabandoAudio) {

			text = 'grabando audio...'

		} else if (this.state.chat && this.state.chat.userOnlineStatus && this.state.chat.userOnlineStatus.Online && ((this.isPatient && (this.state.user && this.state.user.MostrarEstadoConexion)) || !this.isPatient)) {

			text = 'en linea'

		} else if (this.state.chat.userOnlineStatus && this.state.chat.userOnlineStatus.UltimaConexion) {

			const isToday = moment(this.state.chat.userOnlineStatus.UltimaConexion).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY');
			const isYesterday = moment(this.state.chat.userOnlineStatus.UltimaConexion).format('DD/MM/YYYY') === moment().subtract(1, 'd').format('DD-MM-YYYY');

			if (this.isPatient && (this.state.user && !this.state.user.MostrarEstadoConexion)) {
				text = (this.state.currentCase.TipoConsulta === CaseTypes.CONSULTATION)
					? 'Consulta'
					: (this.state.currentCase.TipoConsulta === CaseTypes.MESSAGE)
						? 'Mensaje'
						: (this.state.currentCase.TipoConsulta === CaseTypes.RECIPE)
							? 'Receta'
							: 'Desconocido';
			} else if (isToday) {

				text = `últ. vez hoy a las ${moment(this.state.chat.userOnlineStatus.UltimaConexion).format('HH:mm')}`;

			} else if (isYesterday) {

				text = `últ. vez ayer a las ${moment(this.state.chat.userOnlineStatus.UltimaConexion).format('DD/MM/YYYY HH:mm')}`;
			} else {

				text = `últ. vez ${moment(this.state.chat.userOnlineStatus.UltimaConexion).format('DD/MM/YYYY')} a las ${moment(this.state.chat.userOnlineStatus.UltimaConexion).format('HH:mm')}`;
			}
		} else {

			this.setState({
				subtitle: '',
			});
		}

		this.setState({
			subtitle: text,
		});
	}

	render() {
		const { classes } = this.props
		if (!this.props.user || !this.props.patient || !this.props.currentCase) {
			return null;
		}

		return (
			<div
				style={{
					position: 'relative',
					borderBottom: '1px solid #dedede',
				}}>
				<Card
					color='secondary'
					style={{
						borderTopRightRadius: 4,
					}}>

					<CardHeader
						avatar={
							<Avatar
								aria-label="patient"
								src={this.props.patient ? this.props.patient.ThumbnailId : ''}
							/>
						}
						title={this.props.patient ? `${this.props.patient.Nombre} ${this.props.patient.Apellido}` : 'Cargando..'}
						subheader={this.state.subtitle}
					/>

				</Card>
				{this.props.isOpenCase &&
					(<div
						className={classes.buttonsContainer}>
						<IconButton
							onClick={() => {
								this.props.handleCloseModal()
							}}
							style={{
								color: '#fff',
								marginLeft: 10,
								marginRight: 10
							}}>
							<ExitToAppIcon />
						</IconButton>
					</div>)}
			</div>)
	}
}

const mapStateToProps = (state) => {
	let currentCase = null;
	let user = null;
	let patient = null;

	if (state.chat.openCases && state.chat.openCases[state.chat.activeChat]) {
		currentCase = state.chat.openCases[state.chat.activeChat];
	} else if (state.closedCases.closedCases && state.closedCases.closedCases[state.chat.activeChat]) {
		currentCase = state.closedCases.closedCases[state.chat.activeChat];
	}

	if (currentCase !== null) {
		user = state.references.users[currentCase.Participantes[0]];
		patient = state.references.users[currentCase.PacienteId];
	}

	return {
		chat: state.chat,
		currentCase,
		user,
		patient,
	};
};

export default connect(mapStateToProps, null)(ChatHeader)