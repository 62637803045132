// @flow
import ConsultaRestClient from 'http/resources/ConsultaRestClient';
import ErrorManager, { ApiNonFatalError } from 'services/error-manager';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import _ from 'lodash'


/**
 *  close a case.
 */
function closeCase(notCorresponds) {
	return async (dispatch, getState) => {
		const { chat, closeCase } = getState();
		const ConsultaClient = new ConsultaRestClient(true);
		dispatch({
			type: 'PROFESSIONAL_CLOSE_CASE_CLOSE_CASE_START',
		});


		ConsultaClient.closeCase({
			notCorresponds: notCorresponds || closeCase.notCorresponds,
			consultaId: chat.activeChat,
			bonus: closeCase.bonus,
			chatDuration: 360,
		})
			.then((response) => {
				if (response.data.isFaulted) {
					return Promise.reject(
						new ApiNonFatalError(response),
					);
				}

				dispatch({
					type: 'PROFESSIONAL_CLOSE_CASE_CLOSE_CASE_SUCCESS',
				});
				dispatch({
					type: 'CLEAR_ACTIVE_CHAT',
				});
			})

			.catch((error) => {
				dispatch({
					type: 'PROFESSIONAL_CLOSE_CASE_CLOSE_CASE_FAILURE',
				});
				dispatch({
					type: 'UI_ALERT_SET_ERROR',
					payload: {
						error: ErrorManager.toMessage(error),
					}
				});
			});
	};
}

/**
 *  fetch invested time
 */
function fetchInvestedTime(doqid) {
	return async (dispatch, getState) => {
		const { chat } = getState();

		dispatch({
			type: 'PROFESSIONAL_CLOSE_CASE_INVESTED_TIME_CALCULATE_START',
		})

		const ref1 = firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Estado/Datos/${doqid}/SegundosEnChat`);
		const ref2 = firebase.database().ref(`Consultas/Chats/${chat.activeChat}/Estado/Datos/${doqid}/SegundosEnChat`)

		ref2.once('value', snapshot => {
			if (snapshot && _.isNumber(snapshot.val())) {
				firebase.database().ref().update({
					[`Consultas/Chats/${chat.activeChat}/Estado/Datos/${doqid}/SegundosEnChat`]: snapshot.val() + (moment().diff(chat.initRecordTime, 'seconds'))
				}).then(() => {
					ref1.once('value', (snapshot) => {
						dispatch({
							type: 'CHAT_INIT_RECORD_TIME_RESET'
						})

						if (snapshot && snapshot.val() !== null) {
							dispatch({
								type: 'PROFESSIONAL_CLOSE_CASE_INVESTED_TIME_SET',
								payload: {
									investedTime: snapshot.val(),
								},
							});
						}
					})
				})
			}
		})
	};
}

/**
 *  Set the bonus.
 */
function setBonus(bonus) {
	return async (dispatch) => {
		dispatch({
			type: 'PROFESSIONAL_CLOSE_CASE_BONUS_SET',
			payload: {
				bonus,
			},
		});
	};
}

/**
 *  Set the bonus.
 */
function setNotCorresponds(notCorresponds) {
	return async (dispatch) => {
		dispatch({
			type: 'PROFESSIONAL_CLOSE_CASE_NOT_CORRESPONDS_SET',
			payload: {
				notCorresponds,
			},
		});
	};
}

/**
 *  Erase the error.
 */
function clearError() {
	return async (dispatch) => {
		dispatch({
			type: 'PROFESSIONAL_CLOSE_CASE_CLEAR_ERROR',
		});
	};
}

/**
 *  reset the initial state.
 */
function resetState() {
	return async (dispatch) => {
		dispatch({
			type: 'PROFESSIONAL_CLOSE_CASE_RESET_STATE',
		});
	};
}

export const CloseCaseActions = {
	closeCase,
	setBonus,
	setNotCorresponds,
	fetchInvestedTime,
	resetState,
	clearError,
};