// @flow
import firebase from 'firebase/app';
import 'firebase/database';
import ErrorManager from 'services/error-manager';

/**
 *  Fetch user data.
 */
function fetchUserData(doqid) {
    return async (dispatch) => {
        dispatch({
            type: 'REFERENCES_FETCH_USER_DATA_START',
        });

        /**
         *  Get all user professionals.
         */
        firebase.database().ref(`ViewModels/Referencias/${doqid}`)
            .once('value', (snapshot) => {
                dispatch({
                    type: 'REFERENCES_FETCH_USER_DATA_SUCCESS',
                    payload: {
                        doqid,
                        user: snapshot.val(),
                    },
                });
            }, (error) => {
                dispatch({
                    type: 'REFERENCES_FETCH_USER_DATA_FAILURE',
                    payload: {
                        error: ErrorManager.toMessage(error),
                    },
                });
            });
    };
}

function reset() {
    return {
        type: 'REFERENCES_RESET',
    }
}

export const ReferencesActions = {
    fetchUserData,
    reset,
};
