import firebase from 'firebase/app';
import 'firebase/database'

export default class FirebaseListenersRegistry {
    static add(path) {
        FirebaseListenersRegistry.listeners.push(path);
    }

    static off() {
        return new Promise((resolve) => {
            FirebaseListenersRegistry.listeners.map((path) => {
                firebase.database().ref(path).off();

                return true;
            });

            resolve();
        });
    }
}

FirebaseListenersRegistry.listeners = [];
